import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core'
import { Materiel } from '../../models/materiel'
import { PhotoService } from '../../services/photo.service'
import { Router } from '@angular/router'
import { EvenementService } from '../../services/evenement.service'
import { ToastrService } from 'ngx-toastr'
import { MaterielService } from '../../services/materiel.service'
import { downloadPdfFile, reloadRoute, scrollToTop } from '../../utils/utils'
import * as dayjs from 'dayjs'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { MaterielReceptionModalComponent } from '../materiel-reception-modal/materiel-reception-modal'
import { MaterielLightDto } from '../../models/materiel-light.dto'
import { EvenementDto } from '../../models/evenement-dto'
import { ReferenceService } from '../../services/reference.service'
import { DataSharedService } from '../../services/data-shared.service'

@Component({
  selector: 'lg-materiel-card',
  templateUrl: './materiel-card.component.html',
  styleUrls: ['./materiel-card.component.scss'],
})
export class MaterielCardComponent implements OnInit {
  @Input() chantierCode: string
  @Input() materiel: MaterielLightDto

  downloadingPdf = false
  pointerUntilTodayLoading = false

  materielPhotoUrl: string = null

  constructor(
    private router: Router,
    private photoService: PhotoService,
    private referenceService: ReferenceService,
    private evenementService: EvenementService,
    private dataSharedService: DataSharedService,
    private materielService: MaterielService,
    @Inject(LOCALE_ID) private locale: string,
    private toastr: ToastrService,
    public modalService: NgbModal
  ) {}

  ngOnInit() {
    this.initCardPhoto()
  }

  pointerUntilToday() {
    if (dayjs().isSame(this.materiel.dateDernierEvenement, 'day')) {
      this.toastr.warning("Matériel déjà pointé jusqu'à aujourd'hui")
      return
    }

    this.pointerUntilTodayLoading = true

    this.evenementService
      .createEvenementPointageToday(this.chantierCode, this.materiel.id)
      .subscribe((evenementDto: EvenementDto) => {
        this.materiel.dateDernierEvenement = evenementDto.date
        this.pointerUntilTodayLoading = false
        this.toastr.success("Matériel pointé jusqu'à aujourd'hui")
      })
  }

  endLocation() {
    this.router.navigate(['/chantiers', this.chantierCode, 'materiels', this.materiel.id, 'pointage'], {
      queryParams: {
        typePointageToPreselect: 'SRT',
        date: dayjs(new Date(dayjs().format('YYYY-MM-DD'))),
        from: 'materiel-card'
      },
    })
  }

  public downloadPdf() {
    this.downloadingPdf = true
    this.materielService.downloadMaterielPdf(this.chantierCode, this.materiel.id).subscribe({
      next: (response) => {
        downloadPdfFile(response)
        this.downloadingPdf = false
        this.toastr.success('PDF téléchargé')
      },
      error: (err) => {
        this.downloadingPdf = false
        this.toastr.error(err)
      },
    })
  }

  public reactiverMateriel() {
    // L'événement de sortie est supprimé
    this.evenementService
      .deleteEvenement(this.chantierCode, this.materiel.id, this.materiel.idEvenementSortie)
      .subscribe({
        next: () => {
          // La liste de l'onglet Historique est rafraîchie pour ne plus faire apparaître ce matériel
          reloadRoute(this.router, ['chantiers', this.chantierCode, 'historique'])
          scrollToTop()
          this.toastr.success('Matériel réactivé')
          this.modalService.dismissAll()
        },
      })
  }

  archiver() {
    this.materielService.archiveMateriel(this.chantierCode, this.materiel.id, true).subscribe((archivedMateriel) => {
      this.loadMaterielInCard(archivedMateriel)
      this.toastr.success('Matériel archivé')
      reloadRoute(this.router, ['chantiers', this.chantierCode, 'a-receptionner'])
    })
  }

  desarchiver() {
    this.materielService.archiveMateriel(this.chantierCode, this.materiel.id, false).subscribe((unArchivedMateriel) => {
      this.loadMaterielInCard(unArchivedMateriel)
      this.toastr.success('Matériel désarchivé')
      reloadRoute(this.router, ['chantiers', this.chantierCode, 'a-receptionner'])
    })
  }

  openReceptionModal() {
    const modalRef = this.modalService.open(MaterielReceptionModalComponent)
    modalRef.componentInstance.materiel = this.materiel
    modalRef.componentInstance.chantierCode = this.chantierCode
  }

  getDernierEvenementCSS(materiel: MaterielLightDto) {
    const lastEvenement = materiel.dateDernierEvenement
    if (lastEvenement && materiel.statut !== 'HISTORISE') {
      const dateNow = new Date()
      const differenceBetweenNow = dayjs(dateNow).diff(lastEvenement, 'day')
      if (differenceBetweenNow >= 6) {
        return 'evenement-rouge'
      } else if (dayjs(dateNow).isSame(lastEvenement, 'day')) {
        return 'evenement-vert'
      }
    }
    return null
  }

  private loadMaterielInCard(materiel: Materiel) {
    this.materiel = {
      statut: materiel.getStatut(),
      dateDernierEvenement: materiel.getLastEvenement()?.date,
      uniteTemps: materiel.uniteTemps,
      dateReception: materiel.getDateReception(),
      nom: materiel.nom,
      archived: materiel.archived,
      alerteVGP: materiel.getAlerteVgp(),
      id: materiel.id,
      nomLocatier: materiel.locatier.nom,
      dateReceptionEstimee: materiel.dateReceptionEstimee,
      numParc: materiel.numParc,
      tarifLocation: materiel.tarifLocation,
      idPhotoMateriel: materiel.idPhotoMateriel,
      codeCategorie: materiel.categorie.code,
      idEvenementSortie: materiel.getEvenementSortie()?.id,
      numSerie: materiel.numSerie,
    }
  }

  private initCardPhoto() {
    const photoId = this.materiel.idPhotoMateriel
    if (!photoId) {
      this.referenceService.getCategoriesMateriel().subscribe((categories) => {
        const materielCategorie = categories.find((cat) => cat.code === this.materiel.codeCategorie)
        if (materielCategorie) {
          this.photoService.getById(materielCategorie.photoCategorieId).subscribe((materielPhoto) => {
            this.materielPhotoUrl = materielPhoto.lambdaUrl
          })
        }
      })
    } else {
      this.photoService.getById(this.materiel.idPhotoMateriel).subscribe((materielPhoto) => {
        this.materielPhotoUrl = materielPhoto.lambdaUrl
      })
    }
  }
}
