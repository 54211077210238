import { CategorieMaterielDto } from './categorie-materiel-dto'
import { ChantierDTO } from './chantier-dto'
import { LocatierMaterielDto } from './locatier-materiel-dto'
import { EvenementDto } from './evenement-dto'
import * as dayjs from 'dayjs'
import { StatutEvenementDto } from './statut-evenement-dto'
import { UniteTemps } from './unite-temps'
import { Composant } from './composant'
import { PieceJointe } from './piece-jointe'

export class Materiel {
  id: number
  nom: string
  commentaire: string
  numCommande: string
  numParc: string
  numSerie: string
  tarifLocation: number
  tarifTransportAller: number
  tarifTransportRetour: number
  periodeVGP: number
  dateSortie: Date
  dateVGP: Date
  archived: boolean
  dateReceptionEstimee: Date
  evenements: EvenementDto[] = []
  locatier: LocatierMaterielDto
  categorie: CategorieMaterielDto
  uniteTemps: UniteTemps
  chantier: ChantierDTO
  composants?: Composant[]
  piecesJointes?: PieceJointe[]
  idPhotoMateriel: number

  constructor(init?: Partial<Materiel>) {
    Object.assign(this, init)
    this.composants = this.composants ?? []
    this.piecesJointes = this.piecesJointes ?? []
    this.evenements.forEach((evenement) => (evenement.date = new Date(evenement.date)))
  }

  getComposantQuantite(composantId: number, date: Date) {
    const evenementsComposantInPast = this.evenements.filter(
      (evenement) => evenement.type.code === 'COMP' && dayjs(evenement.date).isBefore(date)
    )
    const lastEvenementComposant = evenementsComposantInPast.at(-1)
    if (lastEvenementComposant) {
      const oldComposantEvenement = lastEvenementComposant.composants.find((composant) => composant.id === composantId)
      return oldComposantEvenement ? oldComposantEvenement.quantite : null
    } else {
      return null
    }
  }

  getStatut(): StatutEvenementDto {
    if (this.evenements.length === 0) {
      return 'A_RECEPTIONNER'
    }

    if (this.evenements.some((evenement) => evenement.type.code === 'SRT')) {
      return 'HISTORISE'
    }

    if (this.evenements.some((evenement) => evenement.type.code === 'RCP')) {
      return 'SUR_CHANTIER'
    }

    return 'A_RECEPTIONNER'
  }

  getLastEvenement() {
    return this.evenements.at(-1)
  }

  getEvenementSortie() {
    return this.evenements.find((e) => e.type.code === 'SRT')
  }

  getDateReception() {
    if (this.evenements.length === 0) {
      return null
    } else {
      return this.evenements.find((evenement) => evenement.type.code === 'RCP').date
    }
  }

  getEvenementsFromDate(date: Date) {
    return this.evenements.filter((evenement) => dayjs(evenement.date).isSame(date, 'day'))
  }

  getLastEvenementDateCss() {
    const lastEvenement = this.getLastEvenement()
    if (lastEvenement) {
      const dateNow = new Date()
      const differenceBetweenNow = dayjs(dateNow).diff(lastEvenement.date, 'day')
      if (differenceBetweenNow >= 6) {
        return 'evenement-rouge'
      } else if (dayjs(dateNow).isSame(lastEvenement.date, 'day')) {
        return 'evenement-vert'
      }
    }
    return null
  }

  getAlerteVgp() {
    if (this.dateVGP && this.periodeVGP) {
      const nextVgpDate = dayjs(this.dateVGP).add(this.periodeVGP, 'month')
      const today = dayjs()

      if (today.isSame(nextVgpDate) || today.isAfter(nextVgpDate)) {
        return 'danger'
      } else if (today.isAfter(nextVgpDate.subtract(15, 'day'))) {
        return 'warning'
      }
    }
    return null
  }
}

export class MaterielSynthese extends Materiel {
  lastEvenementWithDate: string
  downloadingPdf: boolean

  nbJoursUtilisation: number
  nbJoursIntemperie: number
  nbJoursPanne: number
  nbJoursCasse: number
  nbJoursNonPointe: number

  constructor(init?: Partial<MaterielSynthese>) {
    super(init)
    Object.assign(this, init)
  }
}
