import { Component, OnInit, Renderer2 } from '@angular/core'
import { AuthService } from './services/auth.service'
import { DataSharedService } from './services/data-shared.service'
import { ActivatedRoute, Router } from '@angular/router'
import { DeviceDetectorService } from 'ngx-device-detector'

@Component({
  selector: 'lg-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private dataSharedService: DataSharedService,
    private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    public deviceService: DeviceDetectorService
  ) {
    ;(window as any).deviceService = deviceService
    ;(window as any).router = router
  }

  ngOnInit(): void {
    this.removeStartupLoader()
    this.addBodyClass()
  }

  removeStartupLoader() {
    const startupLoader: HTMLElement = document.getElementById('startup-loader')
    if (startupLoader) {
      startupLoader.remove()
    }
  }

  addBodyClass() {
    if (this.deviceService.isDesktop()) {
      this.renderer.addClass(document.body, 'desktop')
    } else {
      this.renderer.addClass(document.body, 'mobile-tablet')
    }
  }
}
