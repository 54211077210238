<div class="d-flex justify-content-center py-5" *ngIf="loading">
  <div class="spinner-border text-primary spinner-lg" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>
<div class="container small-container" *ngIf="evenementForm && !loading && materiel">
  <div class="page-menu">
    <button class="btn btn-light"
            (click)="fromAddEvenement = false; evenementForm.dirty ? modalService.open(ignorerModificationPointageModal) : ignorerModificationPointage()">
      <i class="bi bi-chevron-left"></i><span class="ms-2">Retour</span>
    </button>
    <div>
      <ng-container *ngIf="evenement && evenementForm.get('typePointage').value !== 'SRT'">Modification du pointage
      </ng-container>
      <ng-container *ngIf="!evenement && evenementForm.get('typePointage').value !== 'SRT'">Création d'un pointage
      </ng-container>
      <ng-container *ngIf="evenementForm.get('typePointage').value === 'SRT'">Fin de location</ng-container>
    </div>
    <button class="btn btn-primary" (click)="modalService.open(actionModal)" *ngIf="evenement">
      <span class="me-2">Actions</span><i class="bi bi-list"></i>
    </button>
  </div>

  <ng-container *ngIf="evenementForm.get('typePointage').value !== 'SRT'">
    <hr class="mt-0" />
    <div class="x-large bold text-center">
      <i class="bi bi-calendar4-event"></i> &nbsp; {{date | date:'EEEE dd MMMM yyyy'}}
    </div>
    <hr />
  </ng-container>

  <!-- Présentation -->
  <div class="materiel-presentation mb-3" [ngClass]="!materielPhotoUrl ? 'loadingPhoto' : ''">
    <ng-container *ngIf="!materielPhotoUrl">
      <span aria-hidden="true" class="spinner-border text-primary" role="status"></span>
      <span class="visually-hidden">Loading...</span>
    </ng-container>
    <img *ngIf="materielPhotoUrl" [src]="materielPhotoUrl" class="rounded">
    <!-- Badges sur l'image -->
    <div class="over-badge-vgp" [ngClass]="materiel.getAlerteVgp()"
         *ngIf="materiel.getStatut() === 'SUR_CHANTIER' && materiel.getAlerteVgp()">
      <i class="bi bi-exclamation-triangle-fill"></i>
      <span>VGP</span>
    </div>
    <div class="over-badges">
      <div class="over-badge over-badge-numero" *ngIf="materiel.numParc || materiel.numSerie">
        <ng-container *ngIf="materiel.numParc || !materiel.numSerie; else numSerie">
          <div class="label-numero">N° de parc</div>
          <div class="value-numero">{{materiel.numParc}}</div>
        </ng-container>
        <ng-template #numSerie>
          <div class="label-numero">N° de série</div>
          <div class="value-numero">{{materiel.numSerie}}</div>
        </ng-template>
      </div>
      <div class="over-badge" *ngIf="materiel.tarifLocation">
        {{materiel.tarifLocation + ' € / ' + (materiel.uniteTemps | uniteTempsLabel)}}
      </div>
    </div>
  </div>

  <!-- Dates -->
  <div class="d-flex justify-content-center">
    <div class="materiel-periode mb-3">
      <div class="materiel-date">
        <div>Du</div>
        <div><strong>{{materiel.getDateReception() | date: 'dd.MM.yyyy'}}</strong></div>
      </div>
      <div><i class="bi bi-arrow-right"></i></div>
      <div class="materiel-date">
        <div>Au</div>
        <div [ngClass]="materiel.getLastEvenementDateCss()">
          <strong *ngIf="materiel.evenements && materiel.evenements.length > 0">
            {{materiel.getLastEvenement().date | date: 'dd.MM.yyyy'}}
          </strong>
        </div>
      </div>
    </div>
  </div>

  <form [formGroup]="evenementForm" (ngSubmit)="saveEvenement()" #formDir="ngForm" autocomplete="off">
    <!-- Type Pointage -->
    <div *ngIf="evenementForm.get('typePointage').value !== 'SRT'" class="mb-3">
      <label class="form-label" for="typePointage">Pointage<span class="required">*</span></label>
      <select class="form-select" id="typePointage" formControlName="typePointage"
              [ngClass]="evenementForm.get('typePointage').invalid && (evenementForm.get('typePointage').dirty || evenementForm.get('typePointage').touched ) ? 'is-invalid' : ''">
        <option *ngFor="let typePointage of (typesEvenement)" [ngValue]="typePointage.code"
                [selected]="evenementForm.get('typePointage').value && typePointage.code === evenementForm.get('typePointage').value">
          {{ typePointage.nom }}
        </option>
      </select>
      <div class="invalid-feedback">
        <div *ngIf="evenementForm.get('typePointage').errors?.['required']">Le type de Pointage est obligatoire</div>
      </div>
    </div>

    <ng-container *ngIf="typePointage === 'SRT'">
      <div class="mb-3">
        <label class="form-label" for="dateSortie">Date de sortie<span class="required">*</span></label>
        <input
          [ngClass]="evenementForm.get('dateSortie').invalid && (evenementForm.get('dateSortie').dirty || evenementForm.get('dateSortie').touched) ? 'is-invalid' : ''"
          class="form-control" formControlName="dateSortie" id="dateSortie" max="{{today}}"
          type="date">
        <div
          class="invalid-feedback">
          <div *ngIf="evenementForm.get('dateSortie').errors?.['required']">
            La date de sortie est obligatoire
          </div>
          <div *ngIf="evenementForm.get('dateSortie').errors?.['dateInFuture']">
            La date de sortie ne doit pas être dans le futur
          </div>
        </div>
      </div>

      <div class="mb-3">
        <label class="form-label" for="motifSortie">Motif<span class="required">*</span></label>
        <select
          [ngClass]="evenementForm.get('motifSortie').invalid && (evenementForm.get('motifSortie').dirty || evenementForm.get('motifSortie').touched ) ? 'is-invalid' : ''"
          class="form-select" formControlName="motifSortie"
          id="motifSortie">
          <option *ngFor="let motifSortie of (motifsSortie)" [ngValue]="motifSortie"
                  [selected]="evenementForm.get('motifSortie').value && motifSortie === evenementForm.get('motifSortie').value">
            {{ motifSortie }}
          </option>
        </select>
        <div class="invalid-feedback">
          <div *ngIf="evenementForm.get('motifSortie').errors?.['required']">Le motif de sortie est obligatoire</div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="['EDLE', 'EDLS'].includes(typePointage)">
      <!-- Carburant -->
      <div class="mb-3 custom-slider">
        <label class="form-label" for="carburant">Niveau de carburant / batterie<span class="required">*</span></label>
        <div
          [ngClass]="evenementForm.get('carburant').invalid && (evenementForm.get('carburant').dirty || evenementForm.get('carburant').touched ) ? 'is-invalid' : ''">
          <ngx-slider id="carburant" formControlName="carburant" [options]="sliderOptions"></ngx-slider>
        </div>
      </div>

      <!-- Responsable Livraison \ Enlèvement -->
      <div class="mb-3">
        <label class="form-label" for="responsable">Responsable Livraison \ Enlèvement<span
          class="required">*</span></label>
        <select class="form-select" id="responsable" formControlName="responsable"
                [ngClass]="evenementForm.get('responsable').invalid && (evenementForm.get('responsable').dirty || evenementForm.get('responsable').touched ) ? 'is-invalid' : ''">
          <option *ngFor="let responsable of responsables" [ngValue]="responsable">
            {{ responsable }}
          </option>
        </select>
        <div class="invalid-feedback">
          <div *ngIf="evenementForm.get('responsable').errors?.['required']">Le Responsable Livraison \ Enlèvement est
            obligatoire
          </div>
        </div>
      </div>

      <!-- Transport Mutualisé -->
      <div class="mb-3">
        <label class="form-label">Le transport est-il mutualisé avec d'autres matériels ?<br>(si oui à détailler dans la
          zone "commentaires")<span class="required">*</span></label>
        <div class="btn-group d-block" role="group"
             [ngClass]="evenementForm.get('mutualise').invalid && (evenementForm.get('mutualise').dirty || evenementForm.get('mutualise').touched ) ? 'is-invalid' : ''">
          <input type="radio" class="btn-check" formControlName="mutualise" id="mutualiseNon" value="false">
          <label class="btn btn-outline-dark" for="mutualiseNon">Non</label>

          <input type="radio" class="btn-check" formControlName="mutualise" id="mutualiseOui" value="true">
          <label class="btn btn-outline-dark" for="mutualiseOui">Oui</label>
        </div>
        <div class="invalid-feedback">
          <div *ngIf="evenementForm.get('mutualise').errors?.['required']">Le Transport est-il mutualisé avec d'autres
            matériels est obligatoire
          </div>
        </div>
      </div>
    </ng-container>

    <div *ngIf="typePointage === 'COMP'" class="mb-3">
      <label class="form-label">Composants</label>
      <div class="card" formArrayName="composants">
        <div class="card-body px-2">
          <table class="mb-2 w-100" *ngIf="composants.controls.length">
            <thead>
            <tr>
              <th>Nom</th>
              <th style="width: 60px">Qté</th>
              <th style="width: 60px">Unité</th>
              <th style="width: 80px">P.U.</th>
              <th style="width: 33px;"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let composantForm of composants.controls; let i = index" [formGroup]="composantForm">
              <td>
                <input type="text" class="form-control form-control-sm form-control-composant" formControlName="nom"
                       [ngClass]="composantForm.get('nom').invalid && (composantForm.get('nom').dirty || composantForm.get('nom').touched) ? 'is-invalid' : ''">
              </td>
              <td>
                <input type="number" class="form-control form-control-sm form-control-composant"
                       formControlName="quantite"
                       [ngClass]="composantForm.get('quantite').invalid && (composantForm.get('quantite').dirty || composantForm.get('quantite').touched) ? 'is-invalid' : ''">
              </td>
              <td>
                <select class="form-select form-select-sm form-control-composant" formControlName="unite"
                        [ngClass]="composantForm.get('unite').invalid && (composantForm.get('unite').dirty || composantForm.get('unite').touched) ? 'is-invalid' : ''">
                  <option value="KG">Kg</option>
                  <option value="PIECE">Pce</option>
                </select>
              </td>
              <td>
                <div class="input-group input-group-sm">
                  <input type="number" class="form-control form-control-composant" formControlName="prixUnitaire"
                         [ngClass]="composantForm.get('prixUnitaire').invalid && (composantForm.get('prixUnitaire').dirty || composantForm.get('prixUnitaire').touched) ? 'is-invalid' : ''">
                  <span class="input-group-text" style="padding-left: 2px;padding-right: 2px;">€</span>
                </div>
              </td>
              <td>
                <button class="btn btn-sm btn-primary" type="button" (click)="removeComposant(i)"
                        *ngIf="!composantForm.get('id').value">
                  <i class="bi bi-dash-lg"></i>
                </button>
                <button class="btn btn-sm btn-outline-secondary btn-no-border" type="button"
                        (click)="openComposantActionModal(i)" *ngIf="composantForm.get('id').value">
                  <i class="bi bi-three-dots-vertical"></i>
                </button>
              </td>
            </tr>
            <tr *ngIf="showErrorComposant()">
              <td colspan="5">
                <div class="invalid-feedback d-block">
                  Erreurs détectées dans le formulaire
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <div *ngIf="!composants.controls.length" class="text-center mb-2 fst-italic">Cliquer pour ajouter un composant</div>
          <div class="text-center">
            <button class="btn btn-primary" type="button" (click)="addComposant()">
              <i class="bi bi-plus-lg"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Durée Location -->
    <div *ngIf="!['EDLE', 'EDLS', 'COMP'].includes(typePointage)" class="mb-3">
      <label class="form-label">Durée de location facturée ( Journée )<span class="required">*</span></label>
      <div class="btn-group btn-group-facturation d-block" role="group"
           [ngClass]="evenementForm.get('duree').invalid && (evenementForm.get('duree').dirty || evenementForm.get('duree').touched ) ? 'is-invalid' : ''">
        <input type="radio" class="btn-check" formControlName="duree" id="0" value="0">
        <label class="btn btn-outline-dark" for="0">0</label>

        <input type="radio" class="btn-check" formControlName="duree" id="0.25" value="0.25">
        <label class="btn btn-outline-dark" for="0.25">0.25</label>

        <input type="radio" class="btn-check" formControlName="duree" id="0.5" value="0.5">
        <label class="btn btn-outline-dark" for="0.5">0.5</label>

        <input type="radio" class="btn-check" formControlName="duree" id="0.75" value="0.75">
        <label class="btn btn-outline-dark" for="0.75">0.75</label>

        <input type="radio" class="btn-check" formControlName="duree" id="1" value="1">
        <label class="btn btn-outline-dark" for="1">1</label>
      </div>

      <div class="invalid-feedback">
        <div *ngIf="evenementForm.get('duree').errors?.['required']">La Durée est obligatoire</div>
      </div>
    </div>

    <!-- Coût exceptionnel lié à cet événement (€ HT) -->
    <div class="mb-3">
      <label class="form-label" for="coutExceptionnel">Coût exceptionnel lié à cet événement (€ HT)</label>
      <input id="coutExceptionnel" type="number" formControlName="coutExceptionnel" class="form-control"
             [ngClass]="evenementForm.get('coutExceptionnel').invalid && (evenementForm.get('coutExceptionnel').dirty || evenementForm.get('coutExceptionnel').touched) ? 'is-invalid' : ''">
      <div class="invalid-feedback">
        <div *ngIf="evenementForm.get('coutExceptionnel').errors?.['pattern']">
          Le Coût exceptionnel doit être au format 123 ou 123.12
        </div>
      </div>
    </div>

    <!-- Commentaire -->
    <div class="mb-3">
      <label class="form-label" for="commentaire">Commentaire</label>
      <div
        [ngClass]="evenementForm.get('commentaire').invalid && (evenementForm.get('commentaire').dirty || evenementForm.get('commentaire').touched ) ? 'is-invalid' : ''">
        <textarea class="form-control" formControlName="commentaire" id="commentaire" rows="4"></textarea>
      </div>
      <div class="invalid-feedback">
        <div *ngIf="evenementForm.get('commentaire').errors?.['maxlength']">Le Commentaire doit faire moins de 255
          caractères
        </div>
      </div>
    </div>

    <!-- Photos -->
    <div *ngIf="deviceService.isMobile() || deviceService.isTablet()" class="mb-3">
      <label class="form-label" for="photoButton">Photos</label>
      <ng-container *ngFor="let photoDisplayEntry of getPhotoDisplayEntries()">
        <div class="mb-3">
          <div class="col-12 img_container">
            <img [src]="photoDisplayEntry[1]" class="card-img"
                 onerror="this.src='assets/images/photos/circle-square.png'">
            <button type="button" class="btn photo-clear" (click)="deletePhotoDisplay(photoDisplayEntry[0])">
              <i class="bi bi-x"></i>
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngFor="let photoPreviewEntry of getPhotoPreviewEntries()">
        <div class="mb-3">
          <div id="img_container" class="col-12 img_container">
            <img [src]="'data:image/png;base64,' + photoPreviewEntry[1]" class="card-img"
                 onerror="this.src='assets/images/photos/circle-square.png'">
            <button type="button" class="btn photo-clear" (click)="deletePhotoPreview(photoPreviewEntry[0])">
              <i class="bi bi-x"></i>
            </button>
          </div>
        </div>
      </ng-container>

      <div class="row text-center">
        <input #photoInput (change)="photoInputChange($event)" accept="image/*" hidden="true" type="file" />
        <button (click)="photoInput.click()" [disabled]="photoIsLoading || formIsSubmitting"
                class="btn camera shadow-none" id="photoButton" type="button">
          <i *ngIf="!photoIsLoading && !formIsSubmitting" class="bi bi-camera"></i>
          <div *ngIf="photoIsLoading || formIsSubmitting" class="col-3 text-center spinner-border text-danger"></div>
        </button>
      </div>
    </div>

    <div class="d-flex flex-column">
      <button type="submit" class="btn btn-danger py-3 my-3"
              [disabled]="formIsSubmitting || photoIsLoading">
        <span *ngIf="formIsSubmitting || photoIsLoading" class="spinner-border"></span>
        <span *ngIf="!formIsSubmitting && !photoIsLoading">Enregistrer</span>
      </button>

      <button
        (click)="fromAddEvenement = false ; evenementForm.dirty ? modalService.open(ignorerModificationPointageModal) : ignorerModificationPointage()"
        class="btn btn-outline-secondary py-2" type="button"
        [disabled]="formIsSubmitting">
        Annuler
      </button>
    </div>
  </form>

  <div class="text-center mt-3 mb-3">
    <button class="btn btn-sm btn-outline-secondary btn-no-border py-3" (click)="scrollToTop()">
      Retour en haut <i class="bi bi-chevron-up"></i>
    </button>
  </div>
</div>

<ng-template #ignorerModificationPointageModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Ignorer les modifications ?</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <div>Toutes les modifications du pointage seront perdues.</div>
  </div>
  <div class="modal-footer d-flex flex-column align-items-stretch">
    <button type="button" class="btn btn-primary" (click)="ignorerModificationPointage()">Ignorer</button>
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Annuler</button>
  </div>
</ng-template>

<ng-template #supprimerPointageModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title-supprimer-pointage">Supprimer ce pointage ?</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="nePasSupprimerPointage()"></button>
  </div>
  <div class="modal-body">
    <div>Le pointage sera supprimé.</div>
  </div>
  <div class="modal-footer d-flex flex-column align-items-stretch">
    <button type="button" class="btn btn-primary" (click)="supprimerPointage()">Supprimer</button>
    <button type="button" class="btn btn-outline-secondary" (click)="nePasSupprimerPointage()">Annuler</button>
  </div>
</ng-template>

<ng-template #renseignerEtatDesLieuxSortieModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title-edl-sortie">Procéder à l'état des lieux de sortie ?</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="nePasRenseignerEtatDesLieuxSortie()"></button>
  </div>
  <div class="modal-body">
    <div>Un formulaire d'état des lieux de sortie sera à renseigner.</div>
  </div>
  <div class="modal-footer d-flex flex-column align-items-stretch">
    <button type="button" class="btn btn-primary" (click)="renseignerEtatDesLieuxSortie()">Renseigner</button>
    <button type="button" class="btn btn-outline-secondary" (click)="nePasRenseignerEtatDesLieuxSortie()">Ne Pas Renseigner
    </button>
  </div>
</ng-template>

<ng-template #actionModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Que voulez-vous faire ?</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <div class="list-group">
      <button (click)="modalService.open(supprimerPointageModal)"
              class="list-group-item list-group-item-action"
              type="button">
        <i class="bi bi-trash me-2"></i> Supprimer cet événément
      </button>
      <button
        (click)="fromAddEvenement = true; evenementForm.dirty ? modalService.open(ignorerModificationPointageModal) : ignorerModificationPointage()"
        class="list-group-item list-group-item-action">
        <i class="bi bi-plus-square me-2"></i> Ajouter un autre événement ce jour-là
      </button>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="d-block w-100 btn btn-outline-secondary" (click)="modal.dismiss()">Fermer</button>
  </div>
</ng-template>
