<nav [class.desktop]="deviceService.isDesktop()" class="navbar navbar-secondary-menu bg-light pb-0" *ngIf="chantierCode">
  <div class="container">
    <ul class="nav nav-pills nav-fill w-100 flex-nowrap align-items-center">
      <li class="nav-item">
        <a class="nav-link px-0" [routerLink]="['/chantiers', chantierCode, 'a-receptionner']"
           routerLinkActive="active">
          A réceptionner
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link px-0" [routerLink]="['/chantiers', chantierCode, 'sur-chantier']" routerLinkActive="active">
          Sur chantier
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link px-0" [routerLink]="['/chantiers', chantierCode, 'historique']" routerLinkActive="active">
          Historique
        </a>
      </li>
      <li *ngIf="deviceService.isDesktop()" class="nav-item">
        <a class="nav-link px-0 col-4" [routerLink]="['/chantiers', chantierCode,'synthese']" routerLinkActive="active">
          Synthèse
        </a>
      </li>
    </ul>
  </div>
</nav>
