import { Component, OnDestroy, OnInit } from '@angular/core'
import { User } from '../../../models/user'
import { DeviceDetectorService } from 'ngx-device-detector'
import { RouteParamsService } from '../../../services/route-params.service'
import { Subscription } from 'rxjs'

@Component({
  selector: 'lg-navbar-secondary-menu',
  templateUrl: './navbar-secondary-menu.component.html',
  styleUrls: ['./navbar-secondary-menu.component.scss'],
})
export class NavbarSecondaryMenuComponent implements OnInit, OnDestroy {
  subscription = new Subscription()
  user: User
  chantierCode: string

  constructor(public deviceService: DeviceDetectorService, private routeParamsService: RouteParamsService) {}

  ngOnInit(): void {
    this.subscription.add(
      this.routeParamsService.chantierCode.subscribe((chantierCode) => {
        this.chantierCode = chantierCode
      })
    )
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
}
