import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms'
import { MaterielSaveDto } from '../../models/materiel-save-dto'
import { reloadRoute, scrollToInvalidElement } from '../../utils/utils'
import * as dayjs from 'dayjs'
import { MaterielService } from '../../services/materiel.service'
import { Materiel } from '../../models/materiel'
import { ToastrService } from 'ngx-toastr'
import { Router } from '@angular/router'

@Component({
  selector: 'lg-materiel-reception-modal',
  templateUrl: './materiel-reception-modal.component.html',
  styles: [],
})
export class MaterielReceptionModalComponent implements OnInit {
  @ViewChild('renseignerEtatDesLieuxEntreeModal') modalEDLE: any
  @Input() chantierCode: string
  @Input() materiel: Materiel
  materielReceptionForm: FormGroup
  formIsSubmitting: boolean = false
  today = dayjs().format('YYYY-MM-DD')

  constructor(
    private router: Router,
    public modalService: NgbModal,
    private materielService: MaterielService,
    private toastr: ToastrService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initForm()
  }

  initForm() {
    const dateReceptionValidators = [Validators.required, this.dateInFutureValidator()]

    this.materielReceptionForm = this.fb.group({
      dateReception: ['', dateReceptionValidators],
    })
  }

  dateInFutureValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == '' || control.value == null) {
        return null
      }

      if (dayjs(control.value).isAfter(dayjs(), 'day')) {
        return { dateInFuture: { value: control.value } }
      } else {
        return null
      }
    }
  }

  annulerReceptionMateriel() {
    this.modalService.dismissAll()
  }

  getDateReceptionFromForm() {
    const formValue = this.materielReceptionForm.value
    return formValue.dateReception ? new Date(formValue.dateReception) : null
  }

  receptionnerMateriel() {
    if (!this.materielReceptionForm.valid) {
      this.materielReceptionForm.markAllAsTouched()
      scrollToInvalidElement()
      return
    }

    this.formIsSubmitting = true
    const dateReception: Date = this.getDateReceptionFromForm()

    if (this.materiel) {
      this.materielService
        .receiveMateriel(this.chantierCode, this.materiel.id, dateReception)
        .subscribe((receivedMateriel) => {
          this.materiel = receivedMateriel
          this.formIsSubmitting = false
          this.toastr.success('Matériel réceptionné')
          this.modalService.open(this.modalEDLE)
        })
    }
  }

  renseignerEtatDesLieuxEntree() {
    this.router.navigate(['/chantiers', this.chantierCode, 'materiels', this.materiel.id, 'pointage'], {
      queryParams: {
        typePointageToPreselect: 'EDLE',
        date: dayjs(this.materiel.getDateReception()).format('YYYY-MM-DD'),
      },
    })
    this.modalService.dismissAll()
  }

  nePasRenseignerEtatDesLieuxEntree() {
    this.navigateToAReceptionner()
    this.modalService.dismissAll()
  }

  navigateToAReceptionner() {
    reloadRoute(this.router, ['chantiers', this.chantierCode, 'a-receptionner'])
  }
}
