import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { forkJoin, of, Subscription } from 'rxjs'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Materiel } from '../../models/materiel'
import { MaterielService } from '../../services/materiel.service'
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms'
import { EvenementDto } from '../../models/evenement-dto'
import { EvenementService } from '../../services/evenement.service'
import { TypeEvenementDto } from '../../models/type-evenement-dto'
import { EvenementSaveDto } from '../../models/evenement-save-dto'
import { ToastrService } from 'ngx-toastr'
import imageCompression from 'browser-image-compression'
import { PhotoService } from '../../services/photo.service'
import { LabelType, Options } from '@angular-slider/ngx-slider'
import { reloadRoute, scrollToInvalidElement, scrollToTop } from '../../utils/utils'
import { ReferenceService } from '../../services/reference.service'
import { DeviceDetectorService } from 'ngx-device-detector'
import { photoConfig } from '../../config/photo-config'
import { InfoComplementaireEvenementDto } from '../../models/info-complementaire-evenement-dto'
import * as dayjs from 'dayjs'
import { Composant } from '../../models/composant'
import { ComposantActionModalComponent } from '../composant-action-modal/composant-action-modal.component'
import { DataSharedService } from '../../services/data-shared.service'

@Component({
  selector: 'lg-pointage-form',
  templateUrl: './pointage-form.component.html',
  styleUrls: ['./pointage-form.component.scss'],
})
export class PointageFormComponent implements OnInit, OnDestroy {
  @ViewChild('renseignerEtatDesLieuxSortieModal') modalEDLS: any
  scrollToTop = scrollToTop
  subscription = new Subscription()
  loading = false
  chantierCode: string
  today = dayjs().format('YYYY-MM-DD')

  fromPage: string;

  @ViewChild('photoInput')
  photoInputRef: ElementRef
  photoPreviews: Map<number, string> = new Map<number, string>()
  photoDisplays: Map<number, string> = new Map<number, string>()
  photoIsLoading: boolean = false
  fromAddEvenement: boolean = false
  materielId: number
  evenementId: number
  materiel: Materiel
  typesEvenement: TypeEvenementDto[]
  motifsSortie: string[]
  responsables: string[] = ['Locatier', 'Chantier', 'Tiers']
  formIsSubmitting = false
  evenementForm: FormGroup

  sliderOptions: Options = {
    floor: 0,
    ceil: 100,
    step: 5,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '<b>' + value + ' % </b>'
        default:
          return value + ' %'
      }
    },
  }
  photosToUpload: Map<number, File> = new Map<number, File>()
  photosToDelete: number[] = []
  materielPhotoUrl: string | ArrayBuffer = null
  date: Date
  evenement: EvenementDto

  constructor(
    public deviceService: DeviceDetectorService,
    private router: Router,
    private route: ActivatedRoute,
    private photoService: PhotoService,
    private materielService: MaterielService,
    private dataSharedService: DataSharedService,
    private evenementService: EvenementService,
    private referenceService: ReferenceService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    public modalService: NgbModal
  ) {}

  get typePointage(): string {
    return this.evenementForm.get('typePointage').value
  }

  get composants() {
    return this.evenementForm.get('composants') as FormArray<FormGroup>
  }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      this.fromPage = params['from'];
    });

    // Récupération des paramètres de la navigation
    this.chantierCode = this.route.snapshot.paramMap.get('chantierCode')
    this.materielId = +this.route.snapshot.paramMap.get('materielId')
    this.evenementId = +this.route.snapshot.paramMap.get('evenementId')
    const typePointageToPreselect = this.route.snapshot.queryParamMap.get('typePointageToPreselect')
    const dateAsString = this.route.snapshot.queryParamMap.get('date')
    this.date = dateAsString ? new Date(dateAsString) : null
    this.initForm()

    // Chargement des données
    this.loading = true
    forkJoin([
      this.referenceService.getTypesEvenement(),
      this.materielService.getMateriel(this.chantierCode, this.materielId),
      this.evenementId
        ? this.evenementService.getEvenementById(this.chantierCode, this.materielId, this.evenementId)
        : of(null),
    ]).subscribe(([typesEvenement, materiel, evenement]) => {
      this.typesEvenement = typesEvenement.filter((t) => t.statut !== 'HISTORISE' || t.code === 'EDLS')
      this.motifsSortie = typesEvenement.filter((t) => t.statut === 'HISTORISE' && t.code !== 'EDLS').map((t) => t.nom)
      this.motifsSortie.push('Autre')
      this.materiel = materiel

      this.photoService.getMaterielPhoto(materiel).subscribe((materielPhoto) => {
        this.materielPhotoUrl = materielPhoto.lambdaUrl
      })

      // Si un événement existe déjà à cette date, on remplit le formulaire et on charge les photos
      if (evenement) {
        if (evenement.type.code === 'RCP' || evenement.type.code === 'SRT') {
          reloadRoute(this.router, ['not-found'])
        }
        this.evenement = evenement
        this.date = new Date(dayjs(this.evenement.date).format('YYYY-MM-DD'))
        this.fillForm()
        this.evenement.photoEvenementIds.forEach((id) => {
          this.photoService.getById(id).subscribe((res) => {
            this.photoDisplays.set(res.id, res.lambdaUrl)
          })
        })
      } else if (typePointageToPreselect) {
        this.evenementForm.patchValue({ typePointage: typePointageToPreselect })
      }
      this.loading = false
    })
  }

  isEtatDesLieuxSelected() {
    return ['EDLE', 'EDLS'].includes(this.evenementForm.get('typePointage').value)
  }

  saveEvenement() {
    if (!this.evenementForm.valid) {
      this.evenementForm.markAllAsTouched()
      scrollToInvalidElement()
    } else {
      this.formIsSubmitting = true

      const formValue = this.evenementForm.value
      const infosComplementaires: InfoComplementaireEvenementDto[] = []

      if (formValue.typePointage === 'EDLE' || formValue.typePointage === 'EDLS') {
        infosComplementaires.push({
          nom: 'CARBURANT',
          valeur: formValue.carburant,
        })
        infosComplementaires.push({
          nom: 'RESPONSABLE',
          valeur: formValue.responsable,
        })
        infosComplementaires.push({
          nom: 'MUTUALISE',
          valeur: formValue.mutualise,
        })
      } else if (formValue.typePointage === 'SRT') {
        infosComplementaires.push({
          nom: 'MOTIF_SORTIE',
          valeur: formValue.motifSortie,
        })
        infosComplementaires.push({
          nom: 'DUREE_LOC',
          valeur: formValue.duree,
        })
      } else if (formValue.typePointage !== 'COMP') {
        infosComplementaires.push({
          nom: 'DUREE_LOC',
          valeur: formValue.duree,
        })
      }

      const evenementSave: EvenementSaveDto = {
        codeType: formValue.typePointage,
        coutExceptionnel: formValue.coutExceptionnel,
        commentaire: formValue.commentaire,
        date: formValue.typePointage === 'SRT' ? formValue.dateSortie : this.date,
        materielId: this.materielId,
        infosComplementaires: infosComplementaires,
      }

      if (formValue.typePointage === 'COMP') {
        evenementSave.composants = formValue.composants
      }

      if (this.evenementId) {
        this.evenementService
          .updateEvenement(this.chantierCode, this.materielId, this.evenementId, evenementSave)
          .subscribe({
            next: (updatedEvenement) => {
              this.manageEvenementPhotos(updatedEvenement)
            },
            error: () => {
              this.formIsSubmitting = false
            },
          })
      } else {
        this.evenementService.createEvenement(this.chantierCode, this.materielId, evenementSave).subscribe({
          next: (createdEvenement) => {
            this.manageEvenementPhotos(createdEvenement)
          },
          error: () => {
            this.formIsSubmitting = false
          },
        })
      }
    }
  }

  supprimerPointage() {
    this.loading = true
    this.evenementService.deleteEvenement(this.chantierCode, this.materielId, this.evenement.id).subscribe({
      next: () => {
        this.toastr.success('Pointage supprimé')
        this.modalService.dismissAll()
        this.navigateToCalendrier()
        this.loading = false
      },
      error: () => {
        this.loading = false
      },
    })
    this.modalService.dismissAll()
  }

  nePasSupprimerPointage() {
    this.modalService.dismissAll()
  }

  ignorerModificationPointage() {
    if (this.fromAddEvenement) {
      this.navigateToNewEvenement();
    } else if (this.fromPage === 'materiel-card') {
      this.navigateToSurChantier();
    } else {
      this.navigateToCalendrier();
    }
    this.modalService.dismissAll();
  }

  navigateToNewEvenement() {
    const dateAsString = dayjs(this.date).format('YYYY-MM-DD')
    this.router.navigate(['/chantiers', this.chantierCode, 'materiels', this.materiel.id, 'pointage'], {
      queryParams: { date: dateAsString },
    })
  }

  navigateToCalendrier() {
    this.router.navigate(['/chantiers', this.chantierCode, 'materiels', this.materielId], {
      queryParams: { selectedTab: 'sur-chantier' },
    })
  }

  navigateToSurChantier() {
    this.router.navigate(['/chantiers', this.chantierCode, 'sur-chantier']);
  }

  photoInputChange(event: any) {
    let files = event.target.files

    if (files.length > 0) {
      this.photoIsLoading = true
      const uncompressedFile = files[0]
      imageCompression(uncompressedFile, photoConfig).then((compressedFile) => {
        this.getBase64(compressedFile).then((data) => {
          const startString = 'base64,'
          data = (<string>data).substring((<string>data).lastIndexOf(startString) + startString.length)

          let maxPhotosDisplayKey: number = [...this.photoPreviews.keys()].sort((a, b) => b - a)[0]
          if (!maxPhotosDisplayKey) {
            maxPhotosDisplayKey = 0
          }
          const photoKey: any = (maxPhotosDisplayKey++ + 1).toString()
          this.photosToUpload.set(photoKey, compressedFile)
          this.photoPreviews.set(photoKey, <string>data)
          this.photoIsLoading = false
        })
      })
    }

    this.photoInputRef.nativeElement.value = ''
  }

  getPhotoDisplayEntries(): any {
    return [...this.photoDisplays.entries()].sort((a, b) => a[0] - b[0])
  }

  getPhotoPreviewEntries(): any {
    return [...this.photoPreviews.entries()].sort((a, b) => a[0] - b[0])
  }

  deletePhotoPreview(photoToDeleteKey: number) {
    this.photoPreviews.delete(photoToDeleteKey)
    this.photosToUpload.delete(photoToDeleteKey)
  }

  deletePhotoDisplay(photoToDeleteKey: number) {
    this.photoDisplays.delete(photoToDeleteKey)
    this.photosToDelete.push(photoToDeleteKey)
  }

  renseignerEtatDesLieuxSortie() {
    reloadRoute(this.router, ['chantiers', this.chantierCode, 'materiels', this.materielId, 'pointage'], {
      queryParams: {
        typePointageToPreselect: 'EDLS',
        date: dayjs(this.date).format('YYYY-MM-DD'),
      },
    })
    this.modalService.dismissAll()
  }

  nePasRenseignerEtatDesLieuxSortie() {
    this.navigateToCalendrier()
    this.modalService.dismissAll()
  }

  initForm() {
    const numRegexMaxTwoDigits = '(^[0-9]*.[0-9]{1,2}$)|(^[0-9]*$)'

    this.evenementForm = this.formBuilder.group({
      typePointage: ['', [Validators.required]],
      duree: ['1', [Validators.required]],
      carburant: ['', null],
      responsable: ['', null],
      mutualise: ['', null],
      dateSortie: ['', null],
      motifSortie: ['', null],
      composants: this.formBuilder.array([]),
      coutExceptionnel: ['', { updateOn: 'blur', validators: Validators.pattern(numRegexMaxTwoDigits) }],
      commentaire: ['', Validators.maxLength(255)],
    })

    //this.evenementForm.get('coutExceptionnel').valueChanges.subscribe((value) => {
    //  this.evenementForm.get('coutExceptionnel').setValue((+value).toFixed(2), { emitEvent: false })
    //})

    this.subscribeOnTypePointageValueChanges()
  }

  subscribeOnTypePointageValueChanges() {
    const typePointageControl = this.evenementForm.get('typePointage')
    this.subscription.add(
      typePointageControl.valueChanges.subscribe((typePointageSelectionne) => {
        const dureeControl = this.evenementForm.get('duree')
        const carburantControl = this.evenementForm.get('carburant')
        const responsableControl = this.evenementForm.get('responsable')
        const mutualiseControl = this.evenementForm.get('mutualise')
        const dateSortieControl = this.evenementForm.get('dateSortie')
        const motifSortieControl = this.evenementForm.get('motifSortie')

        if (typePointageSelectionne === 'COMP') {
          this.composants.clear()
          this.materiel.composants.forEach((composant) => this.addComposant(composant))

          dureeControl.setValidators(null)
          dureeControl.disable()
          dateSortieControl.setValidators(null)
          dateSortieControl.disable()
          motifSortieControl.setValidators(null)
          motifSortieControl.disable()
          return
        } else if (typePointageSelectionne === 'EDLE' || typePointageSelectionne === 'EDLS') {
          carburantControl.patchValue(100)
          responsableControl.patchValue('Locatier')

          dateSortieControl.setValidators(null)
          dateSortieControl.disable()
          motifSortieControl.setValidators(null)
          motifSortieControl.disable()

          dureeControl.setValidators(null)
          dureeControl.disable()

          carburantControl.setValidators([Validators.required])
          carburantControl.enable()
          responsableControl.setValidators([Validators.required])
          responsableControl.enable()
          mutualiseControl.setValidators([Validators.required])
          mutualiseControl.enable()
          return
        } else if (typePointageSelectionne === 'SRT') {
          dateSortieControl.setValidators([Validators.required, this.dateInFutureValidator()])
          dateSortieControl.enable()
          motifSortieControl.setValidators([Validators.required])
          motifSortieControl.enable()
          dateSortieControl.patchValue(this.today)
          motifSortieControl.patchValue(this.motifsSortie.find((s) => s === 'Fin de location'))
        } else {
          dateSortieControl.setValidators(null)
          dateSortieControl.disable()
          motifSortieControl.setValidators(null)
          motifSortieControl.disable()
        }

        dureeControl.setValidators([Validators.required])
        dureeControl.enable()
        carburantControl.setValidators(null)
        carburantControl.disable()
        responsableControl.setValidators(null)
        responsableControl.disable()
        mutualiseControl.setValidators(null)
        mutualiseControl.disable()
      })
    )
  }

  getBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  fillForm() {
    let typePointage = this.evenement.type.code

    const coutExceptionnel = this.evenement.coutExceptionnel
    const commentaire = this.evenement.commentaire

    if (['EDLE', 'EDLS'].includes(typePointage)) {
      const carburantInfo = this.evenement.infosComplementaires.find((i) => i.nom === 'CARBURANT')
      const carburant = +carburantInfo.valeur

      const responsableValue = this.evenement.infosComplementaires.find((i) => i.nom === 'RESPONSABLE')
      const responsable = responsableValue.valeur

      const mutualiseValue = this.evenement.infosComplementaires.find((i) => i.nom === 'MUTUALISE')
      const mutualise = mutualiseValue.valeur
      this.evenementForm.patchValue({
        typePointage,
        coutExceptionnel,
        commentaire,
        carburant,
        responsable,
        mutualise,
      })
    } else {
      const dureeLoc = this.evenement.infosComplementaires.find((i) => i.nom === 'DUREE_LOC')
      const duree = dureeLoc?.valeur

      this.evenementForm.patchValue({
        typePointage,
        duree,
        coutExceptionnel,
        commentaire,
      })
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  dateInFutureValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == '' || control.value == null) {
        return null
      }

      if (dayjs(control.value).isAfter(dayjs(), 'day')) {
        return { dateInFuture: { value: control.value } }
      } else {
        return null
      }
    }
  }

  addComposant(composant?: Composant) {
    const prixUnitaireControl = new FormControl(null, { validators: Validators.min(0), updateOn: 'blur' })
    const composantFormGroup = this.formBuilder.group({
      id: [null],
      nom: ['', Validators.required],
      quantite: [0, [Validators.min(0), Validators.required]],
      unite: ['KG', Validators.required],
      prixUnitaire: prixUnitaireControl,
    })

    prixUnitaireControl.valueChanges.subscribe((value) => {
      if (value !== null) {
        prixUnitaireControl.setValue((+value).toFixed(2), { emitEvent: false })
      }
    })

    if (composant) {
      composantFormGroup.patchValue({
        id: composant.id,
        nom: composant.nom,
        quantite: this.materiel.getComposantQuantite(composant.id, this.date),
        unite: composant.unite,
        prixUnitaire: composant.prixUnitaire,
      })

      composantFormGroup.get('nom').disable()
      composantFormGroup.get('unite').disable()
    }

    this.composants.push(composantFormGroup)
  }

  removeComposant(index: number) {
    this.composants.removeAt(index)
  }

  openComposantActionModal(composantIndex: number) {
    const modalRef = this.modalService.open(ComposantActionModalComponent)
    modalRef.componentInstance.composant = this.materiel.composants[composantIndex]
    modalRef.componentInstance.chantierCode = this.chantierCode
    modalRef.componentInstance.materielId = this.materielId
    modalRef.result.then(
      () => {
        // Supprimé
        this.removeComposant(composantIndex)
      },
      () => {
        // Annulé
      }
    )
  }

  showErrorComposant() {
    return this.composants.controls.some(
      (composantForm: FormGroup) => composantForm.invalid && (composantForm.dirty || composantForm.touched)
    )
  }

  private manageEvenementPhotos(evenement: EvenementDto) {
    this.evenement = evenement
    this.photosToUpload.forEach((file) => this.photoService.uploadEvenementPhoto(evenement.id, file).subscribe())
    this.photosToDelete.forEach((id) => this.photoService.deleteById(id).subscribe())
    if (evenement.type.statut === 'HISTORISE' && evenement.type.code !== 'EDLS') {
      this.modalService.open(this.modalEDLS)
    } else {
      this.router.navigate(['/chantiers', this.chantierCode, 'materiels', this.materielId], {
        queryParams: { selectedTab: 'sur-chantier' },
      })
    }
    this.toastr.success('Pointage Enregistré')
    this.formIsSubmitting = false
  }
}
