import { Component, Input } from '@angular/core'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Composant } from '../../models/composant'
import { ComposantService } from '../../services/composant.service'
import { ToastrService } from 'ngx-toastr'

@Component({
  selector: 'lg-composant-action-modal',
  templateUrl: './composant-action-modal.component.html',
  styles: [],
})
export class ComposantActionModalComponent {
  @Input()
  chantierCode: string

  @Input()
  materielId: number

  @Input()
  composant: Composant

  constructor(
    public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    private composantService: ComposantService,
    private toastr: ToastrService
  ) {}

  supprimerComposant() {
    this.composantService.deleteComposant(this.chantierCode, this.materielId, this.composant.id).subscribe(() => {
      this.toastr.success('Composant supprimé')
      this.activeModal.close()
      this.modalService.dismissAll()
    })
  }
}
