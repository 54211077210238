import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { ChantierDTO } from '../models/chantier-dto'
import { MaterielListTab, SelectionableCategorieDto } from '../models/categorie-materiel-dto'

@Injectable({ providedIn: 'root' })
export class DataSharedService {
  chantiers = new BehaviorSubject<ChantierDTO[]>([])

  photosUrls = new BehaviorSubject<Map<number, string>>(new Map())

  categoriesTabs: { type: MaterielListTab; categories: SelectionableCategorieDto[] }[] = [
    { type: 'à-réceptionner', categories: [] },
    { type: 'sur-chantier', categories: [] },
    { type: 'historique', categories: [] },
  ]

  constructor() {}

  saveTabCategories(materielListTab: MaterielListTab, categories: SelectionableCategorieDto[]) {
    this.categoriesTabs.find((categoriesTab) => categoriesTab.type === materielListTab).categories = categories
  }

  getTabCategories(materielListTab: MaterielListTab) {
    return this.categoriesTabs.find((categoriesTab) => categoriesTab.type === materielListTab).categories
  }
}
