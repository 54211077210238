import { Component, OnInit } from '@angular/core'
import { User } from '../../../models/user'
import { AuthService } from '../../../services/auth.service'
import { SwPush } from '@angular/service-worker'
import { NotificationService } from '../../../services/notification.service'
import { OAuthService } from 'angular-oauth2-oidc'

@Component({
  selector: 'lg-navbar-main',
  templateUrl: './navbar-main.component.html',
  styleUrls: ['./navbar-main.component.scss'],
})
export class NavbarMainComponent implements OnInit {
  user: User
  chantierCode: string

  constructor(
    public authService: AuthService,
    private notificationService: NotificationService,
    public swPush: SwPush
  ) {
    ;(window as any).swPush = swPush
  }

  ngOnInit(): void {
    this.user = this.authService.connectedUser
    if (this.user) {
      this.requestAndStoreSubscription()
    }
  }

  requestAndStoreSubscription() {
    this.swPush
      .requestSubscription({ serverPublicKey: this.user.vapidPublicKey })
      .then((pushSubscription) => {
        this.notificationService.storeSubscription(pushSubscription).subscribe()
      })
      .catch((err) => console.error('Could not subscribe to notifications', err))
  }
}
