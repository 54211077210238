import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ToastrService } from 'ngx-toastr'

declare const gapi: any
declare const google: any

@Injectable({
  providedIn: 'root'
})
export class GoogleDriveDownloadService {

  private developerKey = 'AIzaSyDY6KXjirpX6Uz_70WFaiLALR2xn4d_iZI'
  private clientId = '972771213774-t193qr7qukbs2pg10abae52nruh4mppv.apps.googleusercontent.com'

  private oauthAccessToken: any = null
  private downloadCallback: any = null

  private selectedGooglePickerFileUrl: string = null
  private selectedGooglePickerFileName: string = null
  private selectedGooglePickerFileId: string = null

  constructor(private http: HttpClient,
              private toastr: ToastrService) {}

  launchGoogleDownload(callback: any, selectedGooglePickerOauthToken: string, selectedGooglePickerFileUrl: string, selectedGooglePickerFileName: string, selectedGooglePickerFileId: string): void {

    this.selectedGooglePickerFileUrl = selectedGooglePickerFileUrl
    this.selectedGooglePickerFileName = selectedGooglePickerFileName
    this.selectedGooglePickerFileId = selectedGooglePickerFileId
    this.oauthAccessToken = selectedGooglePickerOauthToken

    this.downloadCallback = callback
    if (!this.oauthAccessToken) {
      gapi.load('auth', { 'callback': this.connectGoogleAccount.bind(this) })
    } else {
      this.startDownloadFile()
    }
  }

  private connectGoogleAccount(): void {

    const client = google.accounts.oauth2.initTokenClient({
      'client_id': this.clientId,
      scope: 'https://www.googleapis.com/auth/drive.readonly',
      'immediate': false,
      callback: this.handleAuthResult.bind(this)
    })

    // send authorize request
    // this prompts user to grant access
    client.requestAccessToken()
  }

  private handleAuthResult(authResult: any): void {
    if (authResult && !authResult.error) {
      this.oauthAccessToken = authResult.access_token
      this.startDownloadFile()
    }
  }

  private startDownloadFile(): void {
    if (this.oauthAccessToken) {
      this.gapiDownload()

    }
  }

  private gapiDownload(): void {
    gapi.load('client', () =>
      gapi.client.load('drive', 'v3', () => {
        gapi.auth.setToken({access_token: this.oauthAccessToken});
        gapi.client.drive.files.get({
          fileId: this.selectedGooglePickerFileId
          , alt: 'media'
        })
          .then(
            this.downloadCallback.bind(this)
        )
      })
    )
  }
}
