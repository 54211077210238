import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core'
import { AuthService } from '../../../services/auth.service'
import { ActivatedRoute, Router } from '@angular/router'
import { DeviceDetectorService } from 'ngx-device-detector'
import { RouteParamsService } from '../../../services/route-params.service'

@Component({
  selector: 'lg-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
})
export class AppLayoutComponent implements OnInit, OnDestroy {
  chantierCode: string

  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
    public deviceService: DeviceDetectorService,
    private routeParamsService: RouteParamsService
  ) {}

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'app-layout')

    this.routeParamsService.chantierCode.subscribe((chantierCode) => {
      this.chantierCode = chantierCode
    })
  }

  navigateToRightPage() {
    if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
      if (this.router.url.includes('/a-receptionner')) {
        this.router.navigate(['/chantiers', this.chantierCode, 'sur-chantier'])
      } else if (this.router.url.includes('/sur-chantier')) {
        this.router.navigate(['/chantiers', this.chantierCode, 'historique'])
      }
    }
  }

  navigateToLeftPage() {
    if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
      if (this.router.url.includes('/historique')) {
        this.router.navigate(['/chantiers', this.chantierCode, 'sur-chantier'])
      } else if (this.router.url.includes('/sur-chantier')) {
        this.router.navigate(['/chantiers', this.chantierCode, 'a-receptionner'])
      }
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'app-layout')
  }
}
