import { Component, Input, OnInit } from '@angular/core'
import { MaterielListTab, SelectionableCategorieDto } from '../../models/categorie-materiel-dto'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { MaterielFilter } from '../../models/materiel-filter'

@Component({
  selector: 'lg-materiel-filter-modal',
  templateUrl: './materiel-filter-modal.component.html',
  styles: [],
})
export class MaterielFilterModalComponent implements OnInit {
  @Input()
  categories: SelectionableCategorieDto[] = []
  @Input()
  selectedTab: MaterielListTab

  materielFilter: MaterielFilter
  allCategorieSelected = false

  constructor(public activeModal: NgbActiveModal) {}

  applyFilters() {
    this.activeModal.close(this.materielFilter)
  }

  ngOnInit(): void {
    this.materielFilter = {
      categories: this.categories.map((categorie) => ({ ...categorie })),
    }

    this.checkIfAllCategorieAreSelected()
  }

  selectAllCategorie() {
    this.materielFilter.categories.forEach((categorie) => (categorie.selected = true))
    this.checkIfAllCategorieAreSelected()
  }

  deselectAllCategorie() {
    this.materielFilter.categories.forEach((categorie) => (categorie.selected = false))
    this.checkIfAllCategorieAreSelected()
  }

  checkIfAllCategorieAreSelected() {
    this.allCategorieSelected = this.materielFilter.categories.every((categorie) => categorie.selected)
  }
}
