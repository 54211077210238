import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { MaterielListComponent } from './components/materiel-list/materiel-list.component'
import { AppLayoutComponent } from './components/_layout/app-layout/app-layout.component'
import { AuthGuard } from './guards/auth.guard'
import { SyntheseComponent } from './components/synthese/synthese.component'
import { MaterielViewComponent } from './components/materiel-view/materiel-view.component'
import { PointageFormComponent } from './components/pointage-form/pointage-form.component'
import { MaterielFormComponent } from './components/materiel-form/materiel-form.component'
import { NoChantierComponent } from './components/_error-pages/no-chantier/no-chantier.component'
import { NotFoundComponent } from './components/_error-pages/not-found/not-found.component'
import { SimpleLayoutComponent } from './components/_layout/simple-layout/simple-layout.component'
import { UserNotAuthenticatedComponent } from './components/_error-pages/user-not-authenticated/user-not-authenticated.component'

export const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: '', pathMatch: 'full', component: MaterielListComponent },
      { path: 'chantiers/:chantierCode/a-receptionner', component: MaterielListComponent },
      { path: 'chantiers/:chantierCode/sur-chantier', component: MaterielListComponent },
      { path: 'chantiers/:chantierCode/historique', component: MaterielListComponent },
      { path: 'chantiers/:chantierCode/synthese', component: SyntheseComponent },
    ],
  },
  {
    path: '',
    component: SimpleLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: 'not-found', component: NotFoundComponent },
      { path: 'no-chantier', component: NoChantierComponent },
      { path: 'chantiers/:chantierCode/materiels/create', component: MaterielFormComponent },
      { path: 'chantiers/:chantierCode/materiels/:materielId/edit', component: MaterielFormComponent },
      { path: 'chantiers/:chantierCode/materiels/:materielId', component: MaterielViewComponent },
      { path: 'chantiers/:chantierCode/materiels/:materielId/pointage', component: PointageFormComponent },
      { path: 'chantiers/:chantierCode/materiels/:materielId/pointage/:evenementId', component: PointageFormComponent },
      { path: 'user-not-authenticated', component: UserNotAuthenticatedComponent },
    ],
  },
  { path: '**', redirectTo: 'not-found' },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
