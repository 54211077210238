import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'jourSynthese',
})
export class JourSynthesePipe implements PipeTransform {
  transform(nbJours: number) {
    if (nbJours) {
      return nbJours + 'j'
    } else {
      return '-'
    }
  }
}
