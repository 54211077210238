<div class="modal-header">
  <h4 class="modal-title">
    <span class="text-muted fw-semibold">Composant "{{composant.nom}}"</span>
    <br>
    Que voulez-vous faire ?
  </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <div class="list-group">
    <button (click)="modalService.open(supprimerComposantModal)"
            class="list-group-item list-group-item-action"
            type="button">
      <i class="bi bi-trash me-2"></i> Supprimer ce composant de l'application
    </button>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="d-block w-100 btn btn-outline-dark" (click)="activeModal.dismiss()">Fermer</button>
</div>

<ng-template #supprimerComposantModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title-supprimer-pointage">Supprimer ce composant ?</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modalService.dismissAll()"></button>
  </div>
  <div class="modal-body">
    <div>Le composant "{{composant.nom}}" sera supprimé de l'application, ainsi que toutes les quantités saisies lors
      des autres inventaires. Cette opération est irréversible.
    </div>
  </div>
  <div class="modal-footer d-flex flex-column align-items-stretch">
    <button type="button" class="btn btn-outline-primary" (click)="supprimerComposant()">Supprimer</button>
    <button type="button" class="btn btn-outline-dark" (click)="modalService.dismissAll()">Annuler</button>
  </div>
</ng-template>
