import { Pipe, PipeTransform } from '@angular/core'
import { Materiel } from '../models/materiel'
import { Composant } from '../models/composant'

@Pipe({
  name: 'quantiteComposant',
})
export class QuantiteComposantPipe implements PipeTransform {
  transform(composant: Composant, materiel: Materiel, date?: Date) {
    const quantite = materiel.getComposantQuantite(composant.id, date)
    return quantite === null ? '-' : quantite
  }
}
