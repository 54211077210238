import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs'
import { Materiel } from '../models/materiel'
import { map } from 'rxjs/operators'
import { PieceJointeResponse } from '../models/piece-jointe-response'
import { PieceJointe } from '../models/piece-jointe'
import { ToastrService } from 'ngx-toastr'
import { PieceJointeS3Response } from '../models/piece-jointe-s3-response'

@Injectable({
  providedIn: 'root',
})
export class PieceJointeService {
  constructor(private http: HttpClient,
              private toastr: ToastrService) {}

  uploadPieceJointe(pieceJointe: File): Observable<PieceJointeResponse> {
    const lambdaUploadPath = `${environment.mediaBaseUrl}/media/signed-url/generate/upload?filename=` + pieceJointe.name + `&application=` + `${environment.awsLambdaParam}`

    let formParams = new FormData();
    formParams.append('file', pieceJointe)
    return this.http.post<PieceJointeResponse>(lambdaUploadPath, formParams)
  }

  uploadPieceJointeToS3(pieceJointe: File, jsonDataUploadResponse: PieceJointeResponse): Observable<PieceJointeS3Response> {

    let formParams = new FormData();
    formParams.append('key', jsonDataUploadResponse.url.fields.key)
    formParams.append('x-amz-algorithm', jsonDataUploadResponse.url.fields['x-amz-algorithm'])
    formParams.append('x-amz-credential', jsonDataUploadResponse.url.fields['x-amz-credential'])
    formParams.append('x-amz-date', jsonDataUploadResponse.url.fields['x-amz-date'])
    formParams.append('x-amz-security-token', jsonDataUploadResponse.url.fields['x-amz-security-token'])
    formParams.append('policy', jsonDataUploadResponse.url.fields.policy)
    formParams.append('x-amz-signature', jsonDataUploadResponse.url.fields['x-amz-signature'])
    formParams.append('file', pieceJointe)

    const lambdaUploadS3Path = jsonDataUploadResponse.url.url
    let s3Response: PieceJointeS3Response = { responseStatus: '' };
    return this.http.post<PieceJointeS3Response>(lambdaUploadS3Path, formParams, {observe: 'response'})
      .pipe(
        map((response: HttpResponse<PieceJointeS3Response>) => {
          s3Response.responseStatus = response.status.toString();
          return s3Response;
        })
      );
  }

  getMateriel(chantierCode: string, materielId: number): Observable<Materiel> {
    const path = `${environment.baseUrl}/chantiers/${chantierCode}/materiels/${materielId}`
    return this.http.get<Materiel>(path).pipe(map((materiel) => new Materiel(materiel)))
  }

  getGooglePickerFileFromUrl(oauthAccessToken: string, googlePickerFileUrl: string, googlePickerFileName: string): Observable<File> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + oauthAccessToken
    })

    return this.http.get(googlePickerFileUrl, { headers: headers, responseType: 'blob' }).pipe(
      map(blob => {
        let googlePickerFile = new File([blob], googlePickerFileName, { type: blob.type })
        this.toastr.success('[Google Picker] Fichier Téléchargé : '+ googlePickerFileName)
        return googlePickerFile;
      })
    );
  }

  deletePieceJointe(chantierCode: string, materielId: number, pieceJointeId: number) {
    const path = `${environment.baseUrl}/chantiers/${chantierCode}/materiels/${materielId}/piecesJointes/delete/${pieceJointeId}`
    return this.http.delete(path)
  }

  downloadPieceJointe(chantierCode: string, materielId: number, pieceJointeId: number) : Observable<PieceJointe> {
    const path = `${environment.baseUrl}/chantiers/${chantierCode}/materiels/${materielId}/piecesJointes/download/${pieceJointeId}`
    return this.http.get<PieceJointe>(path)
  }

  download(lambdaUrl: string): Observable<Blob> {
    return this.http.get(lambdaUrl, {
      responseType: 'blob'
    })
  }

}
