import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'
import { EvenementSaveDto } from '../models/evenement-save-dto'
import { EvenementDto } from '../models/evenement-dto'
import * as dayjs from 'dayjs'
import { InfoComplementaireEvenementDto } from '../models/info-complementaire-evenement-dto'

@Injectable({
  providedIn: 'root',
})
export class EvenementService {
  constructor(private http: HttpClient) {}

  getEvenementById(chantierCode: string, materielId: number, evenementId: number) {
    const path = `${environment.baseUrl}/chantiers/${chantierCode}/materiels/${materielId}/evenements/${evenementId}`
    return this.http.get<EvenementDto>(path)
  }

  createEvenement(
    chantierCode: string,
    materielId: number,
    evenementSaveDto: EvenementSaveDto
  ): Observable<EvenementDto> {
    const path = `${environment.baseUrl}/chantiers/${chantierCode}/materiels/${materielId}/evenements`
    return this.http.post<EvenementDto>(path, evenementSaveDto)
  }

  updateEvenement(
    chantierCode: string,
    materielId: number,
    evenementId: number,
    evenementSaveDto: EvenementSaveDto
  ): Observable<EvenementDto> {
    const path = `${environment.baseUrl}/chantiers/${chantierCode}/materiels/${materielId}/evenements/${evenementId}`
    return this.http.put<EvenementDto>(path, evenementSaveDto)
  }

  deleteEvenement(chantierCode: string, materielId: number, evenementId: number) {
    const path = `${environment.baseUrl}/chantiers/${chantierCode}/materiels/${materielId}/evenements/${evenementId}`
    return this.http.delete(path)
  }

  createEvenementPointageToday(chantierCode: string, materielId: number) {
    const infosComplementaires: InfoComplementaireEvenementDto[] = []
    infosComplementaires.push({
      nom: 'DUREE_LOC',
      valeur: '1',
    })

    const evenementSaveDto: EvenementSaveDto = {
      date: new Date(dayjs().format('YYYY-MM-DD')),
      codeType: 'PTG',
      materielId: materielId,
      infosComplementaires: infosComplementaires,
      coutExceptionnel: null,
      commentaire: null,
    }

    return this.createEvenement(chantierCode, materielId, evenementSaveDto)
  }
}
