<div id="footer-secondaire-lg" class="mt-3">
  <div class="d-flex flex-column align-items-center">
    <div class="footer-secondaire-title">
      LOCMAT
    </div>
    <div class="footer-secondaire-subtitle">
      Suivi du matériel en location
    </div>
    <div class="my-3 text-center version-color">
      Version {{frontVersion}} - {{backVersion}} - © Léon Grosse - 2022
    </div>
  </div>
</div>
