import { HttpResponse } from '@angular/common/http'
import { saveAs } from 'file-saver'
import { NgbDate } from '@ng-bootstrap/ng-bootstrap'
import { NavigationExtras, Router } from '@angular/router'
import * as dayjs from 'dayjs'

export function scrollToTop() {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  })
}

export function getUrlWithoutQueryParams(url: string) {
  return url.split('?')[0]
}

export function scrollToInvalidElement() {
  setTimeout(() => {
    const isInvalidElements = document.getElementsByClassName('is-invalid')
    if (isInvalidElements.length > 0) {
      const firstInvalidElement = isInvalidElements[0]
      const positionYToScroll = firstInvalidElement.getBoundingClientRect().top + window.scrollY - 120
      window.scrollTo({ top: positionYToScroll, behavior: 'smooth' })
    }
  }, 100)
}

export function reloadRoute(router: Router, route: any[], extras?: NavigationExtras) {
  router.routeReuseStrategy.shouldReuseRoute = () => false
  router.onSameUrlNavigation = 'reload'
  router.navigate(route, extras)
}

export function downloadPdfFile(httpResponse: HttpResponse<Blob>) {
  const filenameWrap = httpResponse.headers.get('content-disposition').split('filename=')[1]
  const filename = filenameWrap.substring(1, filenameWrap.length - 1)
  saveAs(httpResponse.body, filename)
}

export function downloadPickerFile(httpResponse: HttpResponse<Blob>, filename: string) {
  saveAs(httpResponse.body, filename)
}


export function getDateFromNgbDate(ngbDate: NgbDate) {
  return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day)
}

export function isWeekend(date: Date) {
  const weekendDays = [6, 0]
  return weekendDays.includes(date.getDay())
}

export function isInFutur(date: Date) {
  return dayjs(date).isAfter(dayjs(), 'day')
}

export function isToday(date: Date) {
  return dayjs(date).isSame(dayjs(), 'day')
}
