import { Pipe, PipeTransform } from '@angular/core'
import { UniteTemps } from '../models/unite-temps'

@Pipe({
  name: 'uniteTempsLabel',
})
export class UniteTempsLabelPipe implements PipeTransform {
  transform(uniteTemps: UniteTemps) {
    switch (uniteTemps) {
      case 'HEURE':
        return 'Heure'
      case 'JOURNEE':
        return 'Journée'
      case 'MOIS':
        return 'Mois'
      default:
        return null
    }
  }
}
