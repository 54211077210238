import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { Materiel } from '../models/materiel'
import { environment } from '../../environments/environment'
import { MaterielSaveDto } from '../models/materiel-save-dto'
import { ToastrService } from 'ngx-toastr'
import { map } from 'rxjs/operators'
import { MaterielLightDto } from '../models/materiel-light.dto'
import { Pageable } from '../models/pageable'

@Injectable({
  providedIn: 'root',
})
export class MaterielService {
  constructor(private http: HttpClient, private toastr: ToastrService) {}

  getMateriel(chantierCode: string, materielId: number): Observable<Materiel> {
    const path = `${environment.baseUrl}/chantiers/${chantierCode}/materiels/${materielId}`
    return this.http.get<Materiel>(path).pipe(map((materiel) => new Materiel(materiel)))
  }

  count(chantierCode: string, statut?: string, archived?: boolean): Observable<number> {
    let path = `${environment.baseUrl}/chantiers/${chantierCode}/materiels/count`
    if (statut) {
      path = path.concat(`?statut=${statut}`)
    }
    if (statut && archived !== undefined) {
      path = path.concat(`&archived=${archived}`)
    }

    return this.http.get<number>(path)
  }

  getMaterielsByStatut(chantierCode: string, statut: string, pageable: Pageable): Observable<MaterielLightDto[]> {
    let path = `${environment.baseUrl}/chantiers/${chantierCode}/materiels?statut=${statut}&size=${pageable.size}&page=${pageable.page}`
    pageable.sort.forEach((sort) => (path = path.concat(`&sort=${sort}`)))

    return this.http.get<MaterielLightDto[]>(path)
  }

  createMateriel(chantierCode: string, materielSave: MaterielSaveDto): Observable<Materiel> {
    const path = `${environment.baseUrl}/chantiers/${chantierCode}/materiels`
    return this.http.post<Materiel>(path, materielSave).pipe(map((materiel) => new Materiel(materiel)))
  }

  updateMateriel(chantierCode: string, materielId: number, materielSave: MaterielSaveDto): Observable<Materiel> {
    const path = `${environment.baseUrl}/chantiers/${chantierCode}/materiels/${materielId}`
    return this.http.put<Materiel>(path, materielSave).pipe(map((materiel) => new Materiel(materiel)))
  }

  receiveMateriel(chantierCode: string, materielId: number, dateReception: Date): Observable<Materiel> {
    const path = `${environment.baseUrl}/chantiers/${chantierCode}/materiels/${materielId}/receive`
    return this.http.post<Materiel>(path, dateReception).pipe(map((materiel) => new Materiel(materiel)))
  }

  archiveMateriel(chantierCode: string, materielId: number, archived: boolean): Observable<Materiel> {
    const path = `${environment.baseUrl}/chantiers/${chantierCode}/materiels/${materielId}/archived`
    return this.http
      .get<Materiel>(path, { params: { archived: archived } })
      .pipe(map((materiel) => new Materiel(materiel)))
  }

  downloadMaterielPdf(chantierCode: string, materielId: number) {
    const path = `${environment.baseUrl}/chantiers/${chantierCode}/materiels/${materielId}/pdf`
    return this.http.get(path, { observe: 'response', responseType: 'blob' })
  }

  pointerMaterielsActifs(chantierCode: string): Observable<void> {
    const path = `${environment.baseUrl}/chantiers/${chantierCode}/materiels/pointer-actifs`
    return this.http.post<void>(path, null)
  }
}
