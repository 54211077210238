<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title-date-reception">Réceptionner le matériel ?</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="annulerReceptionMateriel()"></button>
</div>
<div class="modal-body">
  <div class="mb-3">Une date de réception du matériel est à renseigner.</div>

  <div class="row g-0">
    <form [formGroup]="materielReceptionForm" (ngSubmit)="receptionnerMateriel()" autocomplete="off">
      <div class="mt-3 mb-3">
        <label class="form-label" for="dateReception">Date de réception<span class="required">*</span></label>
        <input id="dateReception" type="date" formControlName="dateReception" max="{{today}}" class="form-control"
               [ngClass]="materielReceptionForm.get('dateReception').invalid && (materielReceptionForm.get('dateReception').dirty
                 || materielReceptionForm.get('dateReception').touched) ? 'is-invalid' : ''">
        <div
          class="invalid-feedback">
          <div *ngIf="materielReceptionForm.get('dateReception').errors?.['required']">
            La date de réception est obligatoire
          </div>
          <div *ngIf="materielReceptionForm.get('dateReception').errors?.['dateInFuture']">
            La date de réception ne doit pas être dans le futur
          </div>
        </div>
      </div>

      <div class="d-flex flex-column">
        <button type="submit" class="btn btn-danger py-3 my-3"
                [disabled]="formIsSubmitting">
          <span *ngIf="formIsSubmitting" class="spinner-border"></span>
          <span *ngIf="!formIsSubmitting">Valider</span>
        </button>
        <button type="button" class="btn btn-outline-secondary py-2"
                (click)="$event.stopPropagation(); annulerReceptionMateriel()"
                [disabled]="formIsSubmitting">
          Annuler
        </button>

      </div>
    </form>
  </div>
</div>

<ng-template #renseignerEtatDesLieuxEntreeModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title-edl-entree">Procéder à l'état des lieux d'entrée ?</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="nePasRenseignerEtatDesLieuxEntree()"></button>
  </div>
  <div class="modal-body">
    <div>Un formulaire d'état des lieux d'entrée sera à renseigner.</div>
  </div>
  <div class="modal-footer d-flex flex-column align-items-stretch">
    <button type="button" class="btn btn-primary" (click)="renseignerEtatDesLieuxEntree()">Renseigner</button>
    <button type="button" class="btn btn-outline-secondary" (click)="nePasRenseignerEtatDesLieuxEntree()">Ne Pas Renseigner
    </button>
  </div>
</ng-template>
