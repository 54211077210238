import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, of, switchMap, tap } from 'rxjs'
import { PhotoDto } from '../models/photo-dto'
import { environment } from '../../environments/environment'
import { Materiel } from '../models/materiel'
import { PieceJointeService } from './piece-jointe.service'
import { EvenementDto } from '../models/evenement-dto'
import { DataSharedService } from './data-shared.service'

@Injectable({
  providedIn: 'root',
})
export class PhotoService {
  constructor(
    private http: HttpClient,
    private pieceJointeService: PieceJointeService,
    private dataSharedService: DataSharedService
  ) {}

  getById(photoId: number) {
    if (this.dataSharedService.photosUrls.getValue().has(photoId)) {
      return of({ lambdaUrl: this.dataSharedService.photosUrls.getValue().get(photoId), id: photoId } as PhotoDto)
    } else {
      return this.doGetById(photoId).pipe(
        tap((value) => {
          if (value?.lambdaUrl) {
            const map = this.dataSharedService.photosUrls.getValue()
            map.set(photoId, value.lambdaUrl)
            this.dataSharedService.photosUrls.next(map)
          }
        })
      )
    }
  }

  getMaterielPhoto(materiel: Materiel): Observable<Partial<PhotoDto>> {
    return materiel.idPhotoMateriel
      ? this.getById(materiel.idPhotoMateriel)
      : this.getById(materiel.categorie.photoCategorieId)
  }

  deleteById(photoId: number): Observable<number> {
    const path = `${environment.baseUrl}/pieceJointePhotos/delete/` + photoId
    return this.http.delete<number>(path)
  }

  uploadMaterielPhoto(materielId: number, photo: File): Observable<Materiel> {
    let filename: string
    return this.pieceJointeService.uploadPieceJointe(photo).pipe(
      tap((pieceJointeResponse) => (filename = pieceJointeResponse.filename)),
      switchMap((pieceJointeResponse) => this.pieceJointeService.uploadPieceJointeToS3(photo, pieceJointeResponse)),
      switchMap((pieceJointeS3Response) => {
        const fileName: string = photo.name
        const lambdaId: string = filename

        const path = `${environment.baseUrl}/pieceJointePhotos/savePhotoMateriel/` + materielId

        return this.http.post<Materiel>(path, { fileName, lambdaId })
      })
    )
  }

  uploadEvenementPhoto(evenementId: number, photo: File): Observable<EvenementDto> {
    let filename: string

    return this.pieceJointeService.uploadPieceJointe(photo).pipe(
      tap((pieceJointeResponse) => (filename = pieceJointeResponse.filename)),
      switchMap((pieceJointeResponse) => this.pieceJointeService.uploadPieceJointeToS3(photo, pieceJointeResponse)),
      switchMap((pieceJointeS3Response) => {
        const fileName: string = photo.name
        const lambdaId: string = filename

        const path = `${environment.baseUrl}/pieceJointePhotos/evenement/` + evenementId

        return this.http.post<EvenementDto>(path, { fileName, lambdaId })
      })
    )
  }

  private doGetById(photoId: number): Observable<PhotoDto> {
    const path = `${environment.baseUrl}/pieceJointePhotos/download/` + photoId
    return this.http.get<PhotoDto>(path)
  }
}
