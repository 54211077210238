import { Pipe, PipeTransform } from '@angular/core'
import { StatutEvenementDto } from '../models/statut-evenement-dto'

@Pipe({
  name: 'statutLabel',
})
export class StatutLabelPipe implements PipeTransform {
  transform(statut: StatutEvenementDto) {
    switch (statut) {
      case 'SUR_CHANTIER':
        return 'Sur chantier'
      case 'HISTORISE':
        return 'Historisé'
      case 'A_RECEPTIONNER':
        return 'À réceptionner'
      default:
        return null
    }
  }
}
