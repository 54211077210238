import { Component, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { DataSharedService } from '../../services/data-shared.service'
import { ToastrService } from 'ngx-toastr'
import { forkJoin, Subscription } from 'rxjs'
import { CategorieMaterielDto, MaterielListTab, SelectionableCategorieDto } from '../../models/categorie-materiel-dto'
import { MaterielService } from '../../services/materiel.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { MaterielFilterModalComponent } from '../materiel-filter-modal/materiel-filter-modal.component'
import { ReferenceService } from '../../services/reference.service'
import { reloadRoute } from '../../utils/utils'
import { RouteParamsService } from '../../services/route-params.service'
import { MaterielFilter } from '../../models/materiel-filter'
import { MaterielLightDto } from '../../models/materiel-light.dto'
import { Pageable } from '../../models/pageable'

@Component({
  selector: 'lg-materiel-list',
  templateUrl: './materiel-list.component.html',
  styleUrls: ['./materiel-list.component.scss'],
})
export class MaterielListComponent implements OnInit, OnDestroy {
  subscription = new Subscription()
  chantierCode: string
  loading = false
  pointerMaterielActifLoading = false

  materiels: MaterielLightDto[] = []

  filteredMateriels: MaterielLightDto[] = []
  categories: SelectionableCategorieDto[] = []
  categoriesReference: CategorieMaterielDto[] = []
  archived = false

  materielCount: number
  totalArchivedMateriel: number = undefined
  totalNotArchivedMateriel: number = undefined

  allCategorieSelected = false

  selectedTab: MaterielListTab

  pageableMateriel: Pageable = {
    page: 0,
    size: 15,
    sort: ['lastReceptionDate,DESC', 'id,DESC'],
  }

  loadingMoreMateriels: boolean = false
  moreMaterielsToLoad: boolean = true

  constructor(
    private router: Router,
    private dataSharedService: DataSharedService,
    private materielService: MaterielService,
    private referenceService: ReferenceService,
    private routeParamsService: RouteParamsService,
    private toastr: ToastrService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.chantierCode = this.routeParamsService.chantierCode.getValue()
    this.selectedTab = this.getSelectedTabWithUrl(this.router.url)

    const statut = this.getStatutFromTab()

    this.loading = true
    forkJoin([
      this.referenceService.getCategoriesMateriel(),
      this.materielService.getMaterielsByStatut(this.chantierCode, statut, this.pageableMateriel),
      this.materielService.count(this.chantierCode, statut),
    ]).subscribe(([categories, materiels, count]) => {
      this.materielCount = count
      this.materiels = materiels
      if (materiels?.length < this.pageableMateriel.size) {
        this.moreMaterielsToLoad = false
      }

      this.categoriesReference = categories
      this.categories = categories as SelectionableCategorieDto[]

      this.refreshMaterielsAndCategories()

      this.loading = false
    })
  }

  openActionModal(actionModal: any) {
    this.modalService.open(actionModal)
  }

  openFilterModal() {
    const modalRef = this.modalService.open(MaterielFilterModalComponent)
    modalRef.componentInstance.categories = this.categories
    modalRef.componentInstance.selectedTab = this.selectedTab
    modalRef.result.then(
      (materielFilter: MaterielFilter) => {
        // Appliquer
        this.categories = materielFilter.categories
        this.filterMateriels()
        this.dataSharedService.saveTabCategories(this.selectedTab, this.categories)
      },
      () => {
        // Annuler
      }
    )
  }

  filterMateriels() {
    this.checkIfAllCategorieAreSelected()
    const selectedCategories = this.categories.filter((categorie) => categorie.selected)

    this.filteredMateriels = this.materiels.filter((materiel) =>
      selectedCategories.some((categorie) => categorie.code === materiel.codeCategorie)
    )
    if (this.selectedTab === 'à-réceptionner') {
      this.filteredMateriels = this.filteredMateriels.filter((materiel) => materiel.archived === this.archived)
    }
  }

  selectArchived() {
    this.archived = true
    this.filterMateriels()
  }

  unSelectArchived() {
    this.archived = false
    this.filterMateriels()
  }

  setTotalArchivedMateriels() {
    forkJoin([
      this.materielService.count(this.chantierCode, 'A_RECEPTIONNER', false),
      this.materielService.count(this.chantierCode, 'A_RECEPTIONNER', true),
    ]).subscribe(([countNotArchived, countArchived]) => {
      this.totalArchivedMateriel = countArchived
      this.totalNotArchivedMateriel = countNotArchived
    })
  }

  checkIfAllCategorieAreSelected() {
    this.allCategorieSelected = this.categories.every((categorie) => categorie.selected)
  }

  pointerMaterielsActifsAction() {
    if (this.materielCount == 0) {
      this.toastr.warning('Aucun matériel sur le chantier')
    }
    this.pointerMaterielActifLoading = true
    this.materielService.pointerMaterielsActifs(this.chantierCode).subscribe({
      next: () => {
        this.toastr.success('Matériels actifs pointés')
        this.pointerMaterielActifLoading = false
        reloadRoute(this.router, ['chantiers', this.chantierCode, 'sur-chantier'])
      },
      error: () => {
        this.pointerMaterielActifLoading = false
      },
    })
  }

  getSelectedTabWithUrl(url: string) {
    if (url.includes('/a-receptionner')) {
      return 'à-réceptionner'
    } else if (url.includes('/sur-chantier')) {
      return 'sur-chantier'
    } else if (url.includes('/historique')) {
      return 'historique'
    } else {
      return 'sur-chantier'
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  onLoadMoreMateriels() {
    this.pageableMateriel.page = this.pageableMateriel.page + 1
    this.loadingMoreMateriels = true

    this.materielService
      .getMaterielsByStatut(this.chantierCode, this.getStatutFromTab(), this.pageableMateriel)
      .subscribe((value) => {
        if (value?.length < this.pageableMateriel.size) {
          this.moreMaterielsToLoad = false
        }

        this.materiels.push(...value)
        this.categories = this.categoriesReference as SelectionableCategorieDto[]
        this.refreshMaterielsAndCategories()

        this.loadingMoreMateriels = false
      })
  }

  getLibelleCountAReceptionner() {
    const total = this.archived ? this.totalArchivedMateriel : this.totalNotArchivedMateriel

    return this.filteredMateriels.length + ' / ' + (total === undefined ? '?' : total)
  }

  private refreshMaterielsAndCategories() {
    const savedCategories = this.dataSharedService.getTabCategories(this.selectedTab)

    // On sélectionne chaque catégorie et on compte son nombre de matériel
    this.categories.forEach((categorie) => {
      categorie.nbMateriel = this.materiels.filter((materiel) => materiel.codeCategorie === categorie.code).length
      const savedCategorie = savedCategories.find((savedCategorie) => savedCategorie.code === categorie.code)
      categorie.selected = savedCategorie ? savedCategorie.selected : true
    })

    // On ne garde que les catégories qui ont au moins un matériel
    this.categories = this.categories.filter((categorie) => categorie.nbMateriel)

    if (this.selectedTab === 'à-réceptionner') {
      this.setTotalArchivedMateriels()
    }

    this.filterMateriels()
  }

  private getStatutFromTab(): string {
    if (this.selectedTab === 'sur-chantier') {
      return 'SUR_CHANTIER'
    } else if (this.selectedTab === 'à-réceptionner') {
      return 'A_RECEPTIONNER'
    } else if (this.selectedTab === 'historique') {
      return 'HISTORISE'
    }

    return ''
  }
}
