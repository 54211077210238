import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs'
import {environment} from '../../environments/environment'
import {TypeEvenementDto} from '../models/type-evenement-dto'
import {Cacheable} from 'ts-cacheable'
import {CategorieMaterielDto} from '../models/categorie-materiel-dto'
import {LocatierMaterielDto} from '../models/locatier-materiel-dto'

@Injectable({
  providedIn: 'root',
})
export class ReferenceService {
  constructor(private http: HttpClient) {
  }

  @Cacheable()
  getTypesEvenement(): Observable<TypeEvenementDto[]> {
    const path = `${environment.baseUrl}/types-evenement`
    return this.http.get<TypeEvenementDto[]>(path)
  }

  @Cacheable()
  getCategoriesMateriel(): Observable<CategorieMaterielDto[]> {
    const path = `${environment.baseUrl}/categories-materiel`
    return this.http.get<CategorieMaterielDto[]>(path)
  }

  @Cacheable()
  getLocatiersMateriel(): Observable<LocatierMaterielDto[]> {
    const path = `${environment.baseUrl}/locatiers-materiel`
    return this.http.get<LocatierMaterielDto[]>(path)
  }
}
