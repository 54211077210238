<nav class="navbar-main fixed-top shadow-sm" style="background-color: white">
  <div class="container">
    <div class="row">
      <div class="col-4 col-md-2">
        <img src="../../../../assets/images/logos/logo-lg.png" alt="" height="75">
      </div>
      <div class="col-4 col-md-8 d-flex justify-content-md-center align-items-center">
        <a class="navbar-brand mx-auto" [routerLink]="['/']">LOCMAT</a>
      </div>
      <div class="col-4 col-md-2 d-flex justify-content-end align-items-center" *ngIf="user">
        <ul class="navbar-nav">
          <li class="nav-item" ngbDropdown container="body">
            <div class="user-pp" ngbDropdownToggle>{{user.getInitiales()}}</div>
            <div ngbDropdownMenu class="dropdown-menu">
              <button ngbDropdownItem (click)="authService.logOut()">Se déconnecter</button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
