import { ChantierDTO } from './chantier-dto'

export class User {
  vapidPublicKey: string
  nom: string
  prenom: string
  matricule: string
  lastChantierCode: string
  chantiers: ChantierDTO[]

  subscriptionEndpoint: string
  subscriptionAuth: string
  subscriptionP256dh: string

  constructor(init?: Partial<User>) {
    Object.assign(this, init)
  }

  getDisplayName() {
    return `${this.prenom} ${this.nom}`
  }

  getInitiales() {
    return (this.prenom[0] + this.nom[0]).toUpperCase()
  }

  getChantierCode() {
    if (this.chantiers.length === 0) {
      return null
    } else if (!this.lastChantierCode) {
      return this.chantiers[0].code
    } else {
      return this.chantiers.find((chantier) => chantier.code === this.lastChantierCode).code
    }
  }
}
