import { Injectable } from '@angular/core'

declare const gapi: any
declare const google: any

@Injectable({
  providedIn: 'root'
})
export class GoogleDrivePickerService {

  private developerKey = 'AIzaSyDY6KXjirpX6Uz_70WFaiLALR2xn4d_iZI'
  private clientId = '972771213774-t193qr7qukbs2pg10abae52nruh4mppv.apps.googleusercontent.com'
  private appId = '972771213774'

  private pickerCallback: any = null
  private oauthAccessToken: any = null
  private pickerApiLoaded = false

  public openGooglePicker(callback: any): void {
    this.pickerCallback = callback
    if (!this.oauthAccessToken) {
      gapi.load('auth', { 'callback': this.connectGoogleAccount.bind(this) })
    }

    gapi.load('picker', { 'callback': this.onPickerApiLoad.bind(this) })
  }

  private connectGoogleAccount(): void {
    const client = google.accounts.oauth2.initTokenClient({
      'client_id': this.clientId,
      scope: 'https://www.googleapis.com/auth/drive.readonly',
      'immediate': false,
      callback: this.handleAuthResult.bind(this)
    })
    client.requestAccessToken()
  }

  private handleAuthResult(authResult: any): void {
    if (authResult && !authResult.error) {
      this.oauthAccessToken = authResult.access_token
      this.createPicker()
    }
  }

  private onPickerApiLoad(): void {
    this.pickerApiLoaded = true
    this.createPicker()
  }

  private createPicker(): void {
    if (this.pickerApiLoaded && this.oauthAccessToken) {
      const view = new google.picker.View(google.picker.ViewId.DOCS)
      view.setMimeTypes('image/png,image/jpeg,image/jpg,application/pdf,' +
        'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document')

      let pickerBuilder = new google.picker.PickerBuilder()

      const picker = pickerBuilder
        .addView(view)
        .setAppId(this.appId)
        .setOAuthToken(this.oauthAccessToken)
        .setCallback(this.pickerCallback.bind(this, this.oauthAccessToken))
        .build()
      picker.setVisible(true)
    }
  }
}
