import { Component, ElementRef, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { formatDate } from '@angular/common'
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms'
import { MaterielService } from '../../services/materiel.service'
import { CategorieMaterielDto, MaterielListTab } from 'src/app/models/categorie-materiel-dto'
import { LocatierMaterielDto } from 'src/app/models/locatier-materiel-dto'
import { MaterielSaveDto } from 'src/app/models/materiel-save-dto'
import { ActivatedRoute, Router } from '@angular/router'
import { PhotoService } from 'src/app/services/photo.service'
import imageCompression from 'browser-image-compression'
import { Materiel } from 'src/app/models/materiel'
import { DataSharedService } from '../../services/data-shared.service'
import { forkJoin, Subscription } from 'rxjs'
import { scrollToInvalidElement, scrollToTop } from '../../utils/utils'
import { ReferenceService } from '../../services/reference.service'
import { DeviceDetectorService } from 'ngx-device-detector'
import { ToastrService } from 'ngx-toastr'
import { photoConfig } from '../../config/photo-config'
import * as dayjs from 'dayjs'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'lg-materiel-form',
  templateUrl: './materiel-form.component.html',
  styleUrls: ['./materiel-form.component.scss'],
})
export class MaterielFormComponent implements OnInit, OnDestroy {
  @ViewChild('renseignerEtatDesLieuxEntreeModal') modalEDLE: any
  subscription = new Subscription()
  scrollToTop = scrollToTop

  today = dayjs().format('YYYY-MM-DD')
  minDateSortie: string = null

  @ViewChild('photoInput')
  photoInputRef: ElementRef
  photoToUpload: File = null

  photoData: string
  photoCategorieData: string
  photoPreview: string

  photoIsLoading: boolean = false
  formIsSubmitting: boolean = false

  materielForm: FormGroup
  chantierCode: string
  materielId?: number
  materiel?: Materiel

  categories: CategorieMaterielDto[] = []
  locatiers: LocatierMaterielDto[] = []
  createdMateriel: Materiel = null

  composantsCardIsVisible = false
  selectedTab: MaterielListTab

  constructor(
    public deviceService: DeviceDetectorService,
    private fb: FormBuilder,
    private materielService: MaterielService,
    private referenceService: ReferenceService,
    private photoService: PhotoService,
    private router: Router,
    private route: ActivatedRoute,
    private dataSharedService: DataSharedService,
    @Inject(LOCALE_ID) private locale: string,
    private toastr: ToastrService,
    public modalService: NgbModal
  ) {}

  get composants() {
    return this.materielForm.get('composants') as FormArray<FormGroup>
  }

  ngOnInit(): void {
    scrollToTop()

    this.route.params.subscribe((params) => {
      this.chantierCode = params['chantierCode']
      this.materielId = params['materielId']

      this.route.queryParams.subscribe((queryParams) => {
        this.selectedTab = queryParams['selectedTab'] ?? 'sur-chantier'

        forkJoin([
          this.referenceService.getCategoriesMateriel(),
          this.referenceService.getLocatiersMateriel(),
        ]).subscribe(([categories, locatiers]) => {
          this.categories = categories
          this.locatiers = this.getSortedLocatiers(locatiers)

          if (this.materielId) {
            this.getMaterielAndFillForm(this.materielId)
          } else {
            this.initForm()
            this.photoCategorieData = null
          }
        })
      })
    })
  }

  initForm() {
    const numRegexMaxTwoDigits = '(^[0-9]*.[0-9]{1,2}$)|(^[0-9]*$)'
    const dateReceptionValidators =
      this.selectedTab === 'sur-chantier' ? [Validators.required, this.dateInFutureValidator()] : null
    const dateReceptionEstimeeValidators =
      this.selectedTab === 'à-réceptionner' ? [Validators.required, this.dateInPastValidator()] : null

    this.materielForm = this.fb.group({
      nom: ['', [Validators.required, Validators.maxLength(50)]],
      codeCategorie: ['', Validators.required],
      codeLocatier: ['', Validators.required],
      numCommande: ['', Validators.max(Math.pow(10, 25) - 1)], // Maximum de 25 caractères pour un nombre
      numParc: ['', Validators.maxLength(25)],
      numSerie: ['', Validators.maxLength(50)],
      periodeVGP: [null],
      dateVGP: [null],
      dateReception: ['', dateReceptionValidators],
      dateReceptionEstimee: ['', dateReceptionEstimeeValidators],
      dateSortie: ['', this.dateSortieGreaterThanDateReceptionValidator()],
      uniteTemps: ['', Validators.required],
      composants: this.fb.array([]),
      tarifLocation: ['', { updateOn: 'blur', validators: Validators.pattern('(^[0-9]*.[0-9]{2}$)|(^[0-9]*$)') }],
      tarifTransportAller: ['', { updateOn: 'blur', validators: Validators.pattern(numRegexMaxTwoDigits) }],
      tarifTransportRetour: ['', { updateOn: 'blur', validators: Validators.pattern(numRegexMaxTwoDigits) }],
      commentaire: ['', Validators.maxLength(255)],
    })

    if (this.selectedTab === 'sur-chantier') {
      this.materielForm.get('dateReception').valueChanges.subscribe((dateReception) => {
        this.minDateSortie = dateReception ? dayjs(dateReception).format('YYYY-MM-DD') : null
        this.materielForm.get('dateSortie').updateValueAndValidity()
      })
    } else if (this.selectedTab === 'à-réceptionner') {
      this.materielForm.get('dateReceptionEstimee').valueChanges.subscribe((dateReceptionEstimee) => {
        this.minDateSortie = dateReceptionEstimee ? dayjs(dateReceptionEstimee).format('YYYY-MM-DD') : null
        this.materielForm.get('dateSortie').updateValueAndValidity()
      })
    }

    this.materielForm.get('tarifLocation').valueChanges.subscribe((value) => {
      this.materielForm.get('tarifLocation').setValue((+value).toFixed(2), { emitEvent: false })
    })
    /*
        this.materielForm.get('tarifTransportAller').valueChanges.subscribe((value) => {
          this.materielForm.get('tarifTransportAller').setValue((+value).toFixed(2), { emitEvent: false })
        })

        this.materielForm.get('tarifTransportRetour').valueChanges.subscribe((value) => {
          this.materielForm.get('tarifTransportRetour').setValue((+value).toFixed(2), { emitEvent: false })
        })*/

    this.materielForm.get('codeCategorie').valueChanges.subscribe((codeCategorie) => {
      if (codeCategorie) {
        const selectedCategorie = this.categories.find((categorie) => categorie.code === codeCategorie)
        this.getCategoriePhoto(selectedCategorie.photoCategorieId)
      } else {
        this.photoCategorieData = null
      }
    })

    this.materielForm.get('periodeVGP').valueChanges.subscribe((periodeVGP) => {
      const dateVGPControl = this.materielForm.get('dateVGP')
      if (periodeVGP) {
        dateVGPControl.setValidators([Validators.required, this.dateInFutureValidator()])
        dateVGPControl.enable()
      } else {
        dateVGPControl.setValidators(null)
        dateVGPControl.disable()
      }
    })
  }

  fillForm(materiel: Materiel) {
    if (!this.locatiers.find((locatier) => locatier.code === materiel.locatier.code)) {
      this.locatiers.push(materiel.locatier)
    }

    this.materielForm.patchValue({
      nom: materiel.nom,
      codeCategorie: materiel.categorie.code,
      codeLocatier: materiel.locatier.code,
      dateReception: materiel.getDateReception()
        ? formatDate(materiel.getDateReception(), 'yyyy-MM-dd', this.locale)
        : null,
      dateReceptionEstimee: materiel.dateReceptionEstimee
        ? formatDate(materiel.dateReceptionEstimee, 'yyyy-MM-dd', this.locale)
        : null,
      uniteTemps: materiel.uniteTemps,
      commentaire: materiel.commentaire,
      numCommande: materiel.numCommande,
      numParc: materiel.numParc,
      numSerie: materiel.numSerie,
      periodeVGP: materiel.periodeVGP ? materiel.periodeVGP.toString() : null,
      dateSortie: materiel.dateSortie ? formatDate(materiel.dateSortie, 'yyyy-MM-dd', this.locale) : null,
      dateVGP: materiel.dateVGP ? formatDate(materiel.dateVGP, 'yyyy-MM-dd', this.locale) : null,
      tarifTransportAller: materiel.tarifTransportAller,
      tarifTransportRetour: materiel.tarifTransportRetour,
    })
    if (materiel.tarifLocation) {
      this.materielForm.patchValue({ tarifLocation: materiel.tarifLocation + '' })
    }
    /*if (materiel.tarifTransportAller) {
      this.materielForm.patchValue({ tarifTransportAller: materiel.tarifTransportAller + '' })
    }
    if (materiel.tarifTransportRetour) {
      this.materielForm.patchValue({ tarifTransportRetour: materiel.tarifTransportRetour + '' })
    }*/
  }

  getMaterielAndFillForm(materielId: number) {
    this.materielService.getMateriel(this.chantierCode, materielId).subscribe((materiel) => {
      if (materiel.getStatut() === 'SUR_CHANTIER') {
        this.selectedTab = 'sur-chantier'
      } else if (materiel.getStatut() === 'A_RECEPTIONNER') {
        this.selectedTab = 'à-réceptionner'
      }

      this.initForm()

      if (materiel.idPhotoMateriel) {
        this.photoService.getById(materiel.idPhotoMateriel).subscribe((value) => (this.photoData = value.lambdaUrl))
      } else {
        this.photoData = null
      }

      this.fillForm(materiel)
    })
  }

  getCategoriePhoto(photoId: number) {
    this.photoService.getById(photoId).subscribe((photo) => {
      this.photoCategorieData = photo.lambdaUrl
    })
  }

  saveMateriel() {
    if (!this.materielForm.valid) {
      this.materielForm.markAllAsTouched()
      scrollToInvalidElement()
      return
    }

    this.formIsSubmitting = true
    const materielSave = this.getMaterielSaveFromForm()

    if (this.materielId) {
      this.materielService.updateMateriel(this.chantierCode, this.materielId, materielSave).subscribe({
        next: (updatedMateriel) => {
          this.uploadPhotoIfNeededAndNavigateToUrl(updatedMateriel)
        },
        error: (err) => {
          this.formIsSubmitting = false
        },
      })
    } else {
      this.materielService.createMateriel(this.chantierCode, materielSave).subscribe({
        next: (createdMateriel) => {
          this.createdMateriel = createdMateriel
          this.uploadPhotoIfNeededAndNavigateToUrl(createdMateriel)
        },
        error: (err) => {
          this.formIsSubmitting = false
        },
      })
    }
  }

  getMaterielSaveFromForm() {
    const formValue = this.materielForm.value
    return {
      nom: formValue.nom,
      commentaire: formValue.commentaire ?? null,
      numCommande: formValue.numCommande ?? null,
      numParc: formValue.numParc ?? null,
      numSerie: formValue.numSerie ?? null,
      tarifLocation: formValue.tarifLocation ? +formValue.tarifLocation : null,
      tarifTransportAller: formValue.tarifTransportAller,
      tarifTransportRetour: formValue.tarifTransportRetour,
      //tarifTransportAller: formValue.tarifTransportAller ? +formValue.tarifTransportAller : null,
      //tarifTransportRetour: formValue.tarifTransportRetour ? +formValue.tarifTransportRetour : null,
      periodeVGP: formValue.periodeVGP,
      dateReception: formValue.dateReception ? new Date(formValue.dateReception) : null,
      dateReceptionEstimee: formValue.dateReceptionEstimee ? new Date(formValue.dateReceptionEstimee) : null,
      dateSortie: formValue.dateSortie ? new Date(formValue.dateSortie) : null,
      dateVGP: formValue.dateVGP ? new Date(formValue.dateVGP) : null,
      codeUniteTemps: formValue.uniteTemps,
      codeCategorie: formValue.codeCategorie,
      codeLocatier: formValue.codeLocatier,
      chantierCode: this.chantierCode,
      composants: this.selectedTab === 'sur-chantier' ? formValue.composants : null,
    } as MaterielSaveDto
  }

  uploadPhotoIfNeededAndNavigateToUrl(materiel: Materiel) {
    // Si une photo est sélectionnée alors on l'upload au serveur pour l'ajouter au matériel
    if (this.photoToUpload) {
      this.photoService.uploadMaterielPhoto(materiel.id, this.photoToUpload).subscribe((res) => {
        this.formIsSubmitting = false

        if (!this.materielId && this.selectedTab === 'sur-chantier') {
          this.modalService.open(this.modalEDLE)
          //this.modalEdlChoiceIsVisible = true
        } else if (!this.materielId && this.selectedTab === 'à-réceptionner') {
          this.router.navigate(['/chantiers', this.chantierCode, 'a-receptionner'])
        } else {
          this.toastr.success('Matériel modifié')
          this.router.navigate(['/chantiers', this.chantierCode, 'materiels', materiel.id])
        }
      })
    } else {
      this.formIsSubmitting = false
      if (!this.materielId && this.selectedTab === 'sur-chantier') {
        this.modalService.open(this.modalEDLE)
      } else if (!this.materielId && this.selectedTab === 'à-réceptionner') {
        this.router.navigate(['/chantiers', this.chantierCode, 'a-receptionner'])
      } else {
        this.toastr.success('Matériel modifié')
        this.router.navigate(['/chantiers', this.chantierCode, 'materiels', materiel.id])
      }
    }
  }

  renseignerEtatDesLieuxEntree() {
    this.router.navigate(['/chantiers', this.chantierCode, 'materiels', this.createdMateriel.id, 'pointage'], {
      queryParams: {
        typePointageToPreselect: 'EDLE',
        date: dayjs(this.createdMateriel.getDateReception()).format('YYYY-MM-DD'),
      },
    })
    this.modalService.dismissAll()
  }

  nePasRenseignerEtatDesLieuxEntree() {
    this.navigateToSurChantier()
    this.modalService.dismissAll()
  }

  navigateToSurChantier() {
    this.router.navigate(['/chantiers', this.chantierCode, 'sur-chantier'])
  }

  dateInFutureValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == '' || control.value == null) {
        return null
      }

      if (dayjs(control.value).isAfter(dayjs(), 'day')) {
        return { dateInFuture: { value: control.value } }
      } else {
        return null
      }
    }
  }

  dateInPastValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == '' || control.value == null) {
        return null
      }

      if (dayjs(control.value).isBefore(dayjs(), 'day')) {
        return { dateInPast: { value: control.value } }
      } else {
        return null
      }
    }
  }

  dateSortieGreaterThanDateReceptionValidator(): ValidatorFn {
    return (dateSortieControl: AbstractControl): ValidationErrors | null => {
      if (dateSortieControl.value == '' || dateSortieControl.value == null) {
        return null
      }

      const dateReception =
        this.selectedTab === 'sur-chantier'
          ? this.materielForm.get('dateReception').value
          : this.materielForm.get('dateReceptionEstimee').value

      if (dayjs(dateSortieControl.value).isBefore(dateReception)) {
        return { dateSortieBeforeDateReception: true }
      } else {
        return null
      }
    }
  }

  ignorerModificationMateriel() {
    if (this.materielId) {
      this.router.navigate(['/chantiers', this.chantierCode, 'materiels', this.materielId])
    } else {
      if (this.selectedTab === 'historique') {
        this.router.navigate(['/chantiers', this.chantierCode, 'historique'])
      } else if (this.selectedTab === 'à-réceptionner') {
        this.router.navigate(['/chantiers', this.chantierCode, 'a-receptionner'])
      } else {
        this.router.navigate(['/chantiers', this.chantierCode, 'sur-chantier'])
      }
    }
    this.modalService.dismissAll()
  }

  photoInputChange(event: Event) {
    let files = (event.target as HTMLInputElement).files

    if (files.length > 0) {
      this.photoIsLoading = true
      const uncompressedFile = files[0]
      imageCompression(uncompressedFile, photoConfig).then((compressedFile) => {
        this.getBase64(compressedFile).then((data) => {
          const startString = 'base64,'
          data = (<string>data).substring((<string>data).lastIndexOf(startString) + startString.length)
          this.photoToUpload = compressedFile
          this.photoPreview = <string>data
          this.photoIsLoading = false
        })
      })
    }

    this.photoInputRef.nativeElement.value = ''
  }

  getBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  addComposant() {
    const prixUnitaireControl = new FormControl(null, { validators: Validators.min(0), updateOn: 'blur' })
    this.composants.push(
      this.fb.group({
        nom: ['', Validators.required],
        quantite: [0, [Validators.min(0), Validators.required]],
        unite: ['KG', Validators.required],
        prixUnitaire: prixUnitaireControl,
      })
    )

    prixUnitaireControl.valueChanges.subscribe((value) => {
      if (value !== null) {
        prixUnitaireControl.setValue((+value).toFixed(2), { emitEvent: false })
      }
    })
  }

  removeComposant(index: number) {
    this.composants.removeAt(index)
  }

  showErrorComposant() {
    return this.composants.controls.some(
      (composantForm: FormGroup) => composantForm.invalid && (composantForm.dirty || composantForm.touched)
    )
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  private getSortedLocatiers(locatiers: LocatierMaterielDto[]) {
    const notGenericLocatiers = locatiers.filter((locatier) => !locatier.isGeneric)
    notGenericLocatiers.sort((a, b) => a.nom.localeCompare(b.nom))

    const genericLocatiers = locatiers.filter((locatier) => locatier.isGeneric)
    genericLocatiers.sort((a, b) => a.nom.localeCompare(b.nom))

    // Locatiers génériques en fin de liste
    return [...notGenericLocatiers, ...genericLocatiers]
  }
}
