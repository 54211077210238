<div class="modal-header">
  <h4 class="modal-title">Filtres</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <ul class="list-group">
    <li class="list-group-item">
      <input class="form-check-input me-2" type="checkbox" id="all" [(ngModel)]="allCategorieSelected"
             (change)="!allCategorieSelected ? deselectAllCategorie() : selectAllCategorie()">
      <label class="form-check-label stretched-link" for="all">Toutes</label>
    </li>
    <li class="list-group-item" *ngFor="let categorie of materielFilter.categories">
      <input class="form-check-input me-2" type="checkbox" [id]="categorie.code" [(ngModel)]="categorie.selected"
             (change)="checkIfAllCategorieAreSelected()">
      <label class="form-check-label stretched-link" [for]="categorie.code">{{categorie.nom}}
        ({{categorie.nbMateriel}})</label>
    </li>
  </ul>
</div>
<div class="modal-footer">
  <button type="button" class="d-block w-100 btn btn-primary py-3" (click)="applyFilters()">Appliquer</button>
  <button type="button" class="d-block w-100 btn btn-outline-secondary" (click)="activeModal.dismiss()">Annuler</button>
</div>
