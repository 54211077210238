<lg-navbar-main></lg-navbar-main>
<lg-navbar-chantier></lg-navbar-chantier>
<lg-navbar-secondary-menu></lg-navbar-secondary-menu>
<!-- Version mobile, avec les swipes gauche droite -->
<div class="main-and-footer-container" *ngIf="!deviceService.isDesktop()" (swipeleft)="navigateToRightPage()"
     (swiperight)="navigateToLeftPage()">
  <div class="container" style="flex-grow: 1">
    <router-outlet></router-outlet>
  </div>
  <lg-footer></lg-footer>
</div>

<!-- Version desktop, sans les swipes gauche droite -->
<div class="main-and-footer-container" *ngIf="deviceService.isDesktop()">
  <div class="container" style="flex-grow: 1">
    <router-outlet></router-outlet>
  </div>
  <lg-footer></lg-footer>
</div>

