import { environment } from '../../environments/environment'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private http: HttpClient) {}

  storeSubscription(pushSubscription: PushSubscription) {
    return this.http.post(`${environment.baseUrl}/notifications/store-subscription`, pushSubscription)
  }
}
