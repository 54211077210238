import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { User } from '../models/user'
import { Router } from '@angular/router'
import { environment } from '../../environments/environment'
import { OAuthService } from 'angular-oauth2-oidc'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  connectedUser: User = null

  constructor(private router: Router, private http: HttpClient, private oAuthService: OAuthService) {}

  getConnectedUser(): Observable<User> {
    return this.http
      .get<User>(`${environment.baseUrl}/connected-user`)
      .pipe(map((connectedUser) => new User(connectedUser)))
  }

  logOut() {
    this.oAuthService.logOut({
      client_id: this.oAuthService.clientId,
      redirect_uri: this.oAuthService.redirectUri,
      response_type: 'code',
    })
  }
}
