import { Injectable } from '@angular/core'
import { HammerGestureConfig } from '@angular/platform-browser'
import { DIRECTION_HORIZONTAL } from 'hammerjs'

@Injectable()
export class HammerGestureCustomConfig extends HammerGestureConfig {
  overrides = <any>{
    swipe: { direction: DIRECTION_HORIZONTAL },
    pinch: { enable: false },
    rotate: { enable: false },
  }
}
