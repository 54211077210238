import { LOCALE_ID, NgModule } from '@angular/core'
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { MaterielCardComponent } from './components/materiel-card/materiel-card.component'
import { MaterielListComponent } from './components/materiel-list/materiel-list.component'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { HammerGestureCustomConfig } from './config/hammer-gesture-custom.config'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MaterielFormComponent } from './components/materiel-form/materiel-form.component'
import { OAuthModule, OAuthService } from 'angular-oauth2-oidc'
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks'
import { environment } from '../environments/environment'
import { HttpRequestInterceptor } from './interceptors/http-request-interceptor'
import { CommonModule, registerLocaleData } from '@angular/common'
import { ToastrModule } from 'ngx-toastr'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ServiceWorkerModule } from '@angular/service-worker'
import { MaterielViewComponent } from './components/materiel-view/materiel-view.component'
import { NgbModalConfig, NgbModule, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap'
import localeFr from '@angular/common/locales/fr'
import { NgxSliderModule } from '@angular-slider/ngx-slider'
import { FooterComponent } from './components/_layout/footer/footer.component'
import { ModalModule } from 'ngx-bootstrap/modal'
import { MaterielFilterModalComponent } from './components/materiel-filter-modal/materiel-filter-modal.component'
import { SyntheseComponent } from './components/synthese/synthese.component'
import { PointageFormComponent } from './components/pointage-form/pointage-form.component'
import { JourSynthesePipe } from './pipes/jour-synthese.pipe'
import { NgSelectModule } from '@ng-select/ng-select'
import { SimpleLayoutComponent } from './components/_layout/simple-layout/simple-layout.component'
import { AppLayoutComponent } from './components/_layout/app-layout/app-layout.component'
import { NavbarMainComponent } from './components/_layout/navbar-main/navbar-main.component'
import { NavbarChantierComponent } from './components/_layout/navbar-chantier/navbar-chantier.component'
import { NavbarSecondaryMenuComponent } from './components/_layout/navbar-secondary-menu/navbar-secondary-menu.component'
import { UserNotAuthenticatedComponent } from './components/_error-pages/user-not-authenticated/user-not-authenticated.component'
import { UniteTempsLabelPipe } from './pipes/unite-temps-label.pipe'
import { StatutLabelPipe } from './pipes/statut-label.pipe'
import { NotFoundComponent } from './components/_error-pages/not-found/not-found.component'
import { NoChantierComponent } from './components/_error-pages/no-chantier/no-chantier.component'
import { AUTH_CONFIG } from './config/auth.config'
import { QuantiteComposantPipe } from './pipes/quantite-composant.pipe'
import { MaterielReceptionModalComponent } from './components/materiel-reception-modal/materiel-reception-modal'
import { ComposantActionModalComponent } from './components/composant-action-modal/composant-action-modal.component'

registerLocaleData(localeFr)

@NgModule({
  declarations: [
    AppComponent,
    MaterielCardComponent,
    MaterielFormComponent,
    SyntheseComponent,
    MaterielViewComponent,
    PointageFormComponent,
    MaterielListComponent,
    FooterComponent,
    MaterielFilterModalComponent,
    MaterielReceptionModalComponent,
    UniteTempsLabelPipe,
    StatutLabelPipe,
    JourSynthesePipe,
    QuantiteComposantPipe,
    NotFoundComponent,
    NoChantierComponent,
    SimpleLayoutComponent,
    NavbarMainComponent,
    NavbarChantierComponent,
    NavbarSecondaryMenuComponent,
    UserNotAuthenticatedComponent,
    AppLayoutComponent,
    ComposantActionModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    HammerModule,
    CommonModule,
    NgbModule,
    NgxSliderModule,
    BrowserAnimationsModule,
    NgSelectModule,
    ModalModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-center',
      closeButton: true,
      progressBar: true,
    }),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.baseUrl],
        sendAccessToken: false,
      },
    }),
    ServiceWorkerModule.register('custom-service-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerGestureCustomConfig },
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'fr' },
    StatutLabelPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  // Configuration globale
  constructor(configModal: NgbModalConfig, configTooltip: NgbTooltipConfig, oAuthService: OAuthService) {
    configModal.centered = true

    configTooltip.container = 'body'
    configTooltip.openDelay = 600

    oAuthService.configure(AUTH_CONFIG)
    oAuthService.tokenValidationHandler = new JwksValidationHandler()
    oAuthService.setStorage(localStorage)
  }
}
