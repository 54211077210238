import { Component, OnDestroy, OnInit } from '@angular/core'
import { User } from '../../../models/user'
import { AuthService } from '../../../services/auth.service'
import { ChantierDTO } from '../../../models/chantier-dto'
import { Router } from '@angular/router'
import { MaterielService } from '../../../services/materiel.service'
import { ToastrService } from 'ngx-toastr'
import { RouteParamsService } from '../../../services/route-params.service'
import { reloadRoute } from '../../../utils/utils'
import { Subscription } from 'rxjs'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'lg-navbar-chantier',
  templateUrl: './navbar-chantier.component.html',
  styleUrls: ['./navbar-chantier.component.scss'],
})
export class NavbarChantierComponent implements OnInit, OnDestroy {
  subscription = new Subscription()
  user: User
  chantierCode: string
  selectedChantier?: ChantierDTO

  constructor(
    private router: Router,
    private authService: AuthService,
    private materielService: MaterielService,
    private toastr: ToastrService,
    private routeParamsService: RouteParamsService,
    public modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.routeParamsService.chantierCode.subscribe((chantierCode) => {
        this.chantierCode = chantierCode
        this.user = this.authService.connectedUser
        this.setSelectedChantier()
      })
    )
  }

  setSelectedChantier() {
    this.selectedChantier = this.user.chantiers.find((chantier) => chantier.code === this.chantierCode)
  }

  showToastIfNoMaterielFound(chantierCode: string) {
    if (chantierCode) {
      this.materielService.count(chantierCode).subscribe((exists) => {
        if (!exists) {
          this.toastr.warning('Aucun matériel sur le chantier.')
        }
      })
    }
  }

  selectChantier(chantier: ChantierDTO): void {
    this.selectedChantier = chantier
    this.showToastIfNoMaterielFound(this.selectedChantier.code)
    this.modalService.dismissAll()
    reloadRoute(this.router, ['/chantiers', this.selectedChantier.code, 'sur-chantier'])
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
}
