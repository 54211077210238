import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { NgbDate } from '@ng-bootstrap/ng-bootstrap'
import { ChantierDTO } from '../models/chantier-dto'
import { MaterielListTab, SelectionableCategorieDto } from '../models/categorie-materiel-dto'

/**
 * Permet de récupérer les path params pour les composants non routés
 */
@Injectable({ providedIn: 'root' })
export class RouteParamsService {
  chantierCode = new BehaviorSubject<string>(null)
}
