import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core'
import { MaterielService } from '../../services/materiel.service'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { Materiel } from 'src/app/models/materiel'
import { NgbDate, NgbDatepickerNavigateEvent, NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { DataSharedService } from '../../services/data-shared.service'
import { CalendarDate } from 'src/app/models/calendar-date'
import { CalendarService } from '../../services/calendar.service'
import { downloadPdfFile, getDateFromNgbDate, isInFutur, isToday, isWeekend } from '../../utils/utils'
import * as dayjs from 'dayjs'
import { EvenementDto } from '../../models/evenement-dto'
import { PhotoService } from '../../services/photo.service'
import { EvenementService } from '../../services/evenement.service'
import { ToastrService } from 'ngx-toastr'
import { forkJoin } from 'rxjs'
import { MaterielSaveDto } from '../../models/materiel-save-dto'
import { PieceJointeService } from '../../services/piece-jointe.service'
import { GoogleDrivePickerService } from '../../services/google-drive-picker.service'
import { GoogleDriveDownloadService } from '../../services/google-drive-download.service'
import { PieceJointeResponse } from '../../models/piece-jointe-response'

declare const gapi: any

@Component({
  selector: 'lg-materiel-view',
  templateUrl: './materiel-view.component.html',
  styleUrls: ['./materiel-view.component.scss'],
})
export class MaterielViewComponent implements OnInit {
  materiel: Materiel
  chantierCode: string
  materielId: number
  calendarDates: CalendarDate[] = []
  @ViewChild('chooseEvenementModal') chooseEvenementModal: any
  @ViewChild('documentsAlertModal') documentsAlertModal: any
  modalEvenements: EvenementDto[]
  modalDate: Date
  materielPhotoUrl: string = null
  downloadingPdf = false

  selectedGooglePickerFileID: string = ''
  selectedGooglePickerFileName: string = ''
  selectedGooglePickerFileUrl: string = ''
  selectedGooglePickerOauthToken: string = ''

  pieceJointe: File = null

  constructor(
    public modalService: NgbModal,
    private materielService: MaterielService,
    private evenementService: EvenementService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private photoService: PhotoService,
    private dataSharedService: DataSharedService,
    private calendarService: CalendarService,
    private pieceJointeService: PieceJointeService,
    private googleDrivePickerService: GoogleDrivePickerService,
    private googleDriveDownloadService: GoogleDriveDownloadService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.chantierCode = params['chantierCode']
      this.materielId = +params['materielId']
      this.materielService.getMateriel(this.chantierCode, this.materielId).subscribe((materiel) => {
        this.materiel = materiel
        this.photoService.getMaterielPhoto(materiel).subscribe((materielPhoto) => {
          this.materielPhotoUrl = materielPhoto.lambdaUrl
        })
      })
    })
  }

  selectDate(ngbDate: NgbDate) {
    const date = getDateFromNgbDate(ngbDate)
    const evenementsOfSelectedDay = this.materiel.getEvenementsFromDate(date)

    if (evenementsOfSelectedDay.length === 0) {
      this.navigateToNewEvenement(date)
    } else if (
      evenementsOfSelectedDay.length === 1 &&
      evenementsOfSelectedDay[0].type.code !== 'RCP' &&
      evenementsOfSelectedDay[0].type.code !== 'SRT'
    ) {
      this.navigateToEvenement(evenementsOfSelectedDay[0])
    } else {
      this.modalDate = date
      this.modalEvenements = evenementsOfSelectedDay
      this.modalService.open(this.chooseEvenementModal)
    }
  }

  changeMonth(event: NgbDatepickerNavigateEvent) {
    const date: string = `${event.next.year}-${event.next.month}-01`
    this.calendarService.getCalendarDates(this.chantierCode, this.materiel.id, date).subscribe((calendarDates) => {
      this.calendarDates = calendarDates
    })
  }

  isFutur(ngbDate: NgbDate): boolean {
    return isInFutur(getDateFromNgbDate(ngbDate))
  }

  getShape(ngbDate: NgbDate) {
    const date = getDateFromNgbDate(ngbDate)
    const calendarDate = this.getCalendarDateWithDate(date)
    return calendarDate ? calendarDate.shape : 'NO_SHAPE'
  }

  getCssClass(ngbDate: NgbDate) {
    const date = getDateFromNgbDate(ngbDate)
    const calendarDate = this.getCalendarDateWithDate(date)

    if (isInFutur(date)) {
      return 'NO_SHAPE FUTUR'
    }

    if ((!calendarDate || calendarDate?.shape === 'EMPTY_CIRCLE') && isWeekend(date)) {
      return 'NO_SHAPE WEEKEND'
    }

    const todayClass = isToday(date) ? 'TODAY' : ''
    if (calendarDate) {
      return `${todayClass} ${calendarDate.shape} ${calendarDate.color}`
    } else {
      return `${todayClass} NO_SHAPE`
    }
  }

  getNbEvenements(ngbDate: NgbDate): number {
    const calendarDate = this.getCalendarDateWithDate(getDateFromNgbDate(ngbDate))
    return calendarDate ? calendarDate.nbEvenements : 0
  }

  getCalendarDateWithDate(date: Date) {
    return this.calendarDates.find((calendarDate) => calendarDate.date.getTime() === date.getTime())
  }

  navigateToEvenement(evenement: EvenementDto) {
    this.router.navigate(['/chantiers', this.chantierCode, 'materiels', this.materiel.id, 'pointage', evenement.id])
  }

  navigateToNewEvenement(date: Date) {
    const dateAsString = dayjs(date).format('YYYY-MM-DD')
    this.router.navigate(['/chantiers', this.chantierCode, 'materiels', this.materiel.id, 'pointage'], {
      queryParams: { date: dateAsString },
    })
  }

  downloadPdf() {
    this.downloadingPdf = true
    this.materielService.downloadMaterielPdf(this.chantierCode, this.materiel.id).subscribe({
      next: (response) => {
        downloadPdfFile(response)
        this.downloadingPdf = false
        this.toastr.success('PDF téléchargé')
      },
      error: () => {
        this.downloadingPdf = false
        this.toastr.error('Erreur lors du téléchargement du PDF')
      },
    })
  }

  onPieceJointeChange(event: any) {
    this.pieceJointe = event.target.files[0]
  }

  uploadPieceJointe() {
    if (this.pieceJointe) {
      this.modalService.dismissAll()
      this.pieceJointeService.uploadPieceJointe(this.pieceJointe).subscribe((jsonDataUploadResponse) => {
        this.uploadToAwsLambda(this.pieceJointe, jsonDataUploadResponse)
      })
    } else {
      this.modalService.open(this.documentsAlertModal)
    }
  }

  mapMaterielToMaterielSave(materiel: Materiel) {
    return {
      nom: materiel.nom,
      commentaire: materiel.commentaire ?? null,
      numCommande: materiel.numCommande ?? null,
      numParc: materiel.numParc ?? null,
      numSerie: materiel.numSerie ?? null,
      tarifLocation: materiel.tarifLocation ? +materiel.tarifLocation : null,
      tarifTransportAller: materiel.tarifTransportAller,
      tarifTransportRetour: materiel.tarifTransportRetour,
      periodeVGP: materiel.periodeVGP,
      dateReception: materiel.getDateReception() ? new Date(materiel.getDateReception()) : null,
      dateReceptionEstimee: materiel.dateReceptionEstimee ? new Date(materiel.dateReceptionEstimee) : null,
      dateSortie: materiel.dateSortie ? new Date(materiel.dateSortie) : null,
      dateVGP: materiel.dateVGP ? new Date(materiel.dateVGP) : null,
      codeUniteTemps: materiel.uniteTemps,
      codeCategorie: materiel.categorie.code,
      codeLocatier: materiel.locatier.code,
      chantierCode: this.chantierCode,
      composants: materiel.composants,
      piecesJointes: materiel.piecesJointes,
    } as MaterielSaveDto
  }

  pointerUntilToday() {
    if (dayjs().isSame(this.materiel.getLastEvenement().date, 'day')) {
      this.toastr.warning("Matériel déjà pointé jusqu'à aujourd'hui")
      return
    }

    this.evenementService.createEvenementPointageToday(this.chantierCode, this.materiel.id).subscribe(() => {
      forkJoin([
        this.materielService.getMateriel(this.chantierCode, this.materiel.id),
        this.calendarService.getCalendarDates(this.chantierCode, this.materiel.id, dayjs().format('YYYY-MM-DD')),
      ]).subscribe(([materiel, calendarDates]) => {
        this.materiel = materiel
        this.calendarDates = calendarDates
        this.toastr.success("Matériel pointé jusqu'à aujourd'hui")
      })
    })
  }

  endLocation() {
    this.router.navigate(['/chantiers', this.chantierCode, 'materiels', this.materiel.id, 'pointage'], {
      queryParams: {
        typePointageToPreselect: 'SRT',
        date: dayjs(new Date(dayjs().format('YYYY-MM-DD'))),
        from: 'materiel-view'
      },
    })
  }

  onReturn() {
    switch (this.materiel.getStatut()) {
      case 'A_RECEPTIONNER':
        this.router.navigate(['/chantiers', this.chantierCode, 'a-receptionner'])
        break
      case 'HISTORISE':
        this.router.navigate(['/chantiers', this.chantierCode, 'historique'])
        break
      case 'SUR_CHANTIER':
        this.router.navigate(['/chantiers', this.chantierCode, 'sur-chantier'])
        break
    }
  }

  getLastEvenementComposantDate() {
    const lastEvenementComposant = this.materiel.evenements.filter((evenement) => evenement.type.code === 'COMP').at(-1)
    return lastEvenementComposant ? lastEvenementComposant.date : ''
  }

  deletePieceJointe(pieceJointeId: number) {
    this.pieceJointeService
      .deletePieceJointe(this.chantierCode, this.materielId, pieceJointeId)
      .subscribe((response) => {
        this.materielService.getMateriel(this.chantierCode, this.materiel.id).subscribe((materiel) => {
          this.materiel = materiel
        })
      })
  }

  downloadPieceJointe(pieceJointeId: number) {
    this.pieceJointeService
      .downloadPieceJointe(this.chantierCode, this.materielId, pieceJointeId)
      .subscribe((pieceJointe) => {
        this.pieceJointeService.download(pieceJointe.lambdaUrl).subscribe((blob) => {
          const a = document.createElement('a')
          const objectUrl = URL.createObjectURL(blob)
          a.href = objectUrl
          a.download = pieceJointe.fileName
          a.click()
          URL.revokeObjectURL(objectUrl)
        })
      })
  }

  clicCallGooglePicker(): void {
    this.googleDrivePickerService.openGooglePicker((oauthToken: any, data: any) => {
      this.pickerCallback(oauthToken, data)
    })
  }

  pickerCallback(oauthToken: any, data: any) {
    if (data.action === 'picked') {
      this.selectedGooglePickerFileID = data.docs[0].id
      this.selectedGooglePickerFileName = data.docs[0].name
      this.selectedGooglePickerFileUrl = data.docs[0].url
      this.selectedGooglePickerOauthToken = oauthToken

      this.googleDriveDownloadService.launchGoogleDownload(
        (data: any) => {
          this.downloadCallback(data, this.selectedGooglePickerFileName)
        },
        this.selectedGooglePickerOauthToken,
        this.selectedGooglePickerFileUrl,
        this.selectedGooglePickerFileName,
        this.selectedGooglePickerFileID
      )
    }
  }

  downloadCallback(downloadResult: any, fileName: string) {
    if (
      downloadResult &&
      !downloadResult.error &&
      downloadResult.headers &&
      downloadResult.headers['Content-Type'] &&
      downloadResult.body
    ) {
      const uint8Array: Uint8Array = this.toUint8Array(downloadResult)
      let contentType = downloadResult.headers['Content-Type']
      let blob = new Blob([uint8Array], { type: contentType })
      let file = new File([blob], fileName, { type: contentType })
      this.pieceJointeService.uploadPieceJointe(file).subscribe((jsonDataUploadResponse) => {
        this.uploadToAwsLambda(file, jsonDataUploadResponse)
      })
    }
  }

  private linkPieceJointeToMateriel(fileName: string, lambdaDocumentId: string, lambdaDocumentUrl: string) {
    if (this.materielId) {
      const materielSave: MaterielSaveDto = this.mapMaterielToMaterielSave(this.materiel)
      materielSave.piecesJointes.push({
        fileName: fileName,
        lambdaId: lambdaDocumentId,
        lambdaUrl: lambdaDocumentUrl,
      })

      this.materielService.updateMateriel(this.chantierCode, this.materielId, materielSave).subscribe({
        next: (updatedMateriel) => {
          // Mise à jour du Matériel côté FRONT
          this.materiel = updatedMateriel
        },
        error: (err) => {},
      })
    }
  }

  private toUint8Array(downloadResult: any): Uint8Array {
    const data = downloadResult.body
    const len = data.length
    const uint8Array = new Uint8Array(len)
    for (let i = 0; i < len; i++) {
      uint8Array[i] = data.charCodeAt(i)
    }
    return uint8Array
  }

  private uploadToAwsLambda(file: File, jsonDataUploadResponse: PieceJointeResponse) {
    const fileName: string = file.name
    const lambdaFileId: string = jsonDataUploadResponse.filename
    const lambdaFileUrl: string = jsonDataUploadResponse.url.url

    this.pieceJointeService.uploadPieceJointeToS3(file, jsonDataUploadResponse).subscribe((uploadS3Response) => {
      if (uploadS3Response.responseStatus.includes('204')) {
        this.linkPieceJointeToMateriel(fileName, lambdaFileId, lambdaFileUrl)
        this.cdr.detectChanges()
      }
    })
  }
}
