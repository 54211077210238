import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'
import { Cacheable } from 'ts-cacheable'

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  constructor(private http: HttpClient) {}

  @Cacheable()
  getBackVersion(): Observable<string> {
    const path = `${environment.baseUrl}/version`
    return this.http.get(path, { responseType: 'text' })
  }
}
