import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core'
import { AuthService } from '../../../services/auth.service'

@Component({
  selector: 'lg-simple-layout',
  templateUrl: './simple-layout.component.html',
  styleUrls: ['./simple-layout.component.scss'],
})
export class SimpleLayoutComponent implements OnInit, OnDestroy {
  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'simple-layout')
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'simple-layout')
  }
}
