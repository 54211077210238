<nav class="navbar-chantier navbar-dark">
  <div class="container">
    <div class="choix-chantier" (click)="modalService.open(choixChantierModal)"
         [class.active-hover]="user.chantiers && user.chantiers.length > 1" *ngIf="user">
      <div class="" *ngIf="selectedChantier">
        {{selectedChantier.code + ' ' + selectedChantier.nom}}
      </div>
      <div class="" *ngIf="!selectedChantier">
        Aucun chantier affecté
      </div>
      <div *ngIf="user.chantiers && user.chantiers.length > 1">
        <i class="bi bi-chevron-down"></i>
      </div>
    </div>
  </div>
</nav>

<ng-template #choixChantierModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title-edl-entree">Choix du chantier</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <div class="list-group">
      <button type="button" class="list-group-item list-group-item-action" style="height: 60px"
              [class.active]="chantier === selectedChantier"
              *ngFor="let chantier of user.chantiers" (click)="selectChantier(chantier)">{{chantier.code}}
        - {{chantier.nom}}
      </button>
    </div>
  </div>
</ng-template>
