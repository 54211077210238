import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router'
import { Observable } from 'rxjs'
import { OAuthService } from 'angular-oauth2-oidc'
import { AuthService } from '../services/auth.service'
import { RouteParamsService } from '../services/route-params.service'
import { getUrlWithoutQueryParams } from '../utils/utils'

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  connectedUserHasBeenLoadedOnce = false

  constructor(
    private oAuthService: OAuthService,
    private authService: AuthService,
    private router: Router,
    private routeParamsService: RouteParamsService
  ) {
    ;(window as any).oAuthService = oAuthService
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.oAuthService.hasValidIdToken() && this.authService.connectedUser) {
      this.redirectToSurChantierIfNeeded(getUrlWithoutQueryParams(state.url))
      return true
    } else if (
      this.oAuthService.hasValidIdToken() &&
      !this.authService.connectedUser &&
      this.connectedUserHasBeenLoadedOnce
    ) {
      if (state.url !== '/user-not-authenticated') {
        this.router.navigate(['/user-not-authenticated'], { skipLocationChange: true })
        return false
      } else {
        return true
      }
    }

    return new Observable<boolean>((observer) => {
      this.oAuthService.tryLogin().then(() => {
        if (!this.oAuthService.hasValidIdToken()) {
          this.oAuthService.initCodeFlow()
        } else {
          this.authService.getConnectedUser().subscribe({
            next: (connectedUser) => {
              this.connectedUserHasBeenLoadedOnce = true
              this.authService.connectedUser = connectedUser
              this.redirectToSurChantierIfNeeded(getUrlWithoutQueryParams(state.url))

              observer.next(true)
              observer.complete()
            },
            error: () => {
              this.connectedUserHasBeenLoadedOnce = true
              this.router.navigate(['/user-not-authenticated'], { skipLocationChange: true })

              observer.next(false)
              observer.complete()
            },
          })
        }
      })
    })
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.redirectToSurChantierIfNeeded(state.url)

    const chantierCode = childRoute.paramMap.get('chantierCode')
    if (chantierCode) {
      this.routeParamsService.chantierCode.next(chantierCode)
    }

    return true
  }

  redirectToSurChantierIfNeeded(url: string) {
    if (url === '/') {
      const chantierCode = this.authService.connectedUser.getChantierCode()
      if (chantierCode) {
        this.router.navigate(['/chantiers', chantierCode, 'sur-chantier'])
      } else {
        this.router.navigate(['/no-chantier'], { skipLocationChange: true })
      }
    }
  }
}
