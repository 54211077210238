<div *ngIf="materiel" class="card mb-4 ru-shadow-2"
     [routerLink]="['/chantiers', chantierCode, 'materiels', materiel.id]">
  <div class="card-header" style="background: white;text-align: center">
    <div style="font-size: 18px;font-weight: 500;">{{materiel.nom}}</div>
    <div style="font-size: 14px;color: gray;margin-top: -8px;">{{materiel.nomLocatier}}</div>
  </div>
  <div class="materiel-presentation" [ngClass]="!materielPhotoUrl ? 'loadingPhoto' : ''">
    <ng-container *ngIf="!materielPhotoUrl">
      <span aria-hidden="true" class="spinner-border text-primary" role="status"></span>
      <span class="visually-hidden">Loading...</span>
    </ng-container>
    <img *ngIf="materielPhotoUrl" [src]="materielPhotoUrl" style="border-bottom: 1px solid rgba(0, 0, 0, .175);">
    <!-- Badges sur l'image -->
    <div class="over-badge-vgp" [ngClass]="materiel.alerteVGP"
         *ngIf="materiel.statut === 'SUR_CHANTIER' && materiel.alerteVGP">
      <i class="bi bi-exclamation-triangle-fill"></i>
      <span>VGP</span>
    </div>
    <div class="over-badges">
      <div class="over-badge over-badge-numero" *ngIf="materiel.numParc || materiel.numSerie">
        <ng-container *ngIf="materiel.numParc || !materiel.numSerie; else numSerie">
          <div class="label-numero">N° de parc</div>
          <div class="value-numero">{{materiel.numParc}}</div>
        </ng-container>
        <ng-template #numSerie>
          <div class="label-numero">N° de série</div>
          <div class="value-numero">{{materiel.numSerie}}</div>
        </ng-template>
      </div>
      <div class="over-badge" *ngIf="materiel.tarifLocation">
        {{materiel.tarifLocation + ' € / ' + (materiel.uniteTemps | uniteTempsLabel)}}
      </div>
    </div>
  </div>

  <div class="card-body">
    <!-- Dates -->
    <div *ngIf="materiel.statut === 'A_RECEPTIONNER'" class="d-flex justify-content-center">
      <div class="d-flex justify-content-center">
        <div class="materiel-periode mb-3">
          <div class="materiel-date">
            <div>Date de réception estimée</div>
            <div><strong>{{materiel.dateReceptionEstimee | date: 'dd.MM.yyyy'}}</strong></div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="materiel.statut === 'SUR_CHANTIER' || materiel.statut === 'HISTORISE'"
         class="d-flex justify-content-center">
      <div class="d-flex justify-content-center">
        <div class="materiel-periode mb-3">
          <div class="materiel-date">
            <div>Du</div>
            <div><strong>{{materiel.dateReception | date: 'dd.MM.yyyy'}}</strong></div>
          </div>
          <div><i class="bi bi-arrow-right"></i></div>
          <div class="materiel-date">
            <div>Au</div>
            <div [ngClass]="getDernierEvenementCSS(materiel)">
              <strong *ngIf="materiel.dateDernierEvenement">
                {{materiel.dateDernierEvenement | date: 'dd.MM.yyyy'}}
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Boutons -->
    <div *ngIf="materiel.statut === 'A_RECEPTIONNER'" class="d-flex flex-column gap-2">
      <button (click)="openReceptionModal();$event.stopPropagation()" class="btn btn-outline-danger py-2 w-100"
              type="button">
        <strong>Réceptionner</strong>
      </button>
      <button *ngIf="materiel.archived" (click)="desarchiver();$event.stopPropagation()"
              class="btn btn-outline-danger py-2 w-100"
              type="button">
        <strong>Désarchiver</strong>
      </button>
      <button *ngIf="! materiel.archived" (click)="archiver();$event.stopPropagation()"
              class="btn btn-outline-danger py-2 w-100"
              type="button">
        <strong>Archiver</strong>
      </button>
    </div>

    <div *ngIf="materiel.statut === 'SUR_CHANTIER'" class="flex-container">
      <button (click)="pointerUntilToday(); $event.stopPropagation()" class="btn btn-outline-danger py-2 flex-grow-1"
              type="button" [disabled]="pointerUntilTodayLoading">
        <ng-container *ngIf="pointerUntilTodayLoading">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span class="visually-hidden">Loading...</span>
        </ng-container>
        <ng-container *ngIf="!pointerUntilTodayLoading">
          <strong>Pointer jusqu'à aujourd'hui</strong>
        </ng-container>
      </button>
      <i class="bi bi-sign-stop-fill red-icon" (click)="endLocation(); $event.stopPropagation()"></i>
    </div>



    <div *ngIf="materiel.statut === 'HISTORISE'" class="d-flex flex-column gap-2">
      <button (click)="$event.stopPropagation();modalService.open(reactiverModal)" class="btn btn-outline-primary py-2">
        <strong>Réactiver</strong>
      </button>
      <button (click)="$event.stopPropagation();downloadPdf()" class="btn btn-outline-primary btn-no-border py-2">
        <ng-container *ngIf="downloadingPdf">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span class="visually-hidden">Loading...</span>
        </ng-container>
        <ng-container *ngIf="!downloadingPdf">
          <strong>Télécharger le compte-rendu PDF</strong>
          <i class="ms-2 bi bi-download"></i>
        </ng-container>
      </button>
    </div>
  </div>
</div>

<ng-template #reactiverModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Réactiver ce matériel ?</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <div>Une fois réactivé, le matériel reviendra sur le chantier.</div>
  </div>
  <div class="modal-footer d-flex flex-column align-items-stretch">
    <button type="button" class="btn btn-primary" (click)="reactiverMateriel()">Réactiver</button>
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Annuler</button>
  </div>
</ng-template>


