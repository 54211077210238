import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { Materiel } from '../models/materiel'
import { environment } from '../../environments/environment'
import { MaterielSaveDto } from '../models/materiel-save-dto'
import { ToastrService } from 'ngx-toastr'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class ComposantService {
  constructor(private http: HttpClient) {}

  deleteComposant(chantierCode: string, materielId: number, composantId: number) {
    const path = `${environment.baseUrl}/chantiers/${chantierCode}/materiels/${materielId}/composants/${composantId}`
    return this.http.delete(path)
  }
}
