import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'
import { HttpClient } from '@angular/common/http'
import { PeriodDto } from '../models/period-dto'
import { MaterielSynthese } from '../models/materiel'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class SyntheseService {
  constructor(private http: HttpClient) {}

  getSynthesePeriod(chantierCode: string): Observable<PeriodDto> {
    const path = `${environment.baseUrl}/chantiers/${chantierCode}/synthese-period`
    return this.http.get<PeriodDto>(path)
  }

  getMaterielsSynthese(chantierCode: string, month: string): Observable<MaterielSynthese[]> {
    const path = `${environment.baseUrl}/chantiers/${chantierCode}/synthese-materiels`
    return this.http
      .get<MaterielSynthese[]>(path, { params: { month: month } })
      .pipe(
        map((materielsSynthese) => materielsSynthese.map((materielSynthese) => new MaterielSynthese(materielSynthese)))
      )
  }
}
