import { Injectable } from '@angular/core'
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { catchError } from 'rxjs/operators'
import { from, lastValueFrom, Observable, throwError } from 'rxjs'
import { environment } from 'src/environments/environment'
import { OAuthService } from 'angular-oauth2-oidc'
import { ToastrService } from 'ngx-toastr'
import { Router } from '@angular/router'

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(private oAuthService: OAuthService, private toastr: ToastrService, private router: Router) {
    ;(window as any).oAuthService = oAuthService
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handle(req, next))
  }

  private async handle(req: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    const lambdaUploadPath = "/media/signed-url/generate/upload"

    if (req.url.match(environment.baseUrl)) {
      req = await this.addAuthorizationHeader(req)
    } else if (req.url.includes(lambdaUploadPath)) {
      req = await this.addAuthorizationHeader(req)
    }

    return lastValueFrom(
      next.handle(req).pipe(
        catchError((error) => {
          if (req.url.includes('/synthese-period') && error.status === 404) {
            return throwError(error)
          } else {
            this.showError(error)
            return throwError(error)
          }
        })
      )
    )
  }

  private async addAuthorizationHeader(req: HttpRequest<any>) {
    await this.refreshToken()

    if (this.oAuthService.hasValidIdToken) {
      req = req.clone({
        setHeaders: { Authorization: `Bearer ${this.oAuthService.getIdToken()}` },
      })
    }
    return req
  }

  private showError(httpErrorResponse: HttpErrorResponse) {
    if (httpErrorResponse.status === 404 || httpErrorResponse.status === 401) {
      this.router.navigate(['/not-found'], { skipLocationChange: true })
    }
    if (typeof httpErrorResponse.error.error_description === 'string') {
      this.toastr.error(httpErrorResponse.error.error_description, `Erreur ${httpErrorResponse.status}`)
    } else if (httpErrorResponse.error) {
      this.toastr.error(httpErrorResponse.error.message, `Erreur ${httpErrorResponse.status}`)
    } else {
      this.toastr.error(httpErrorResponse.error)
    }
  }

  private refreshToken(): Promise<void> {
    return new Promise<void>((resolve) => {
      if (+new Date() >= this.oAuthService.getAccessTokenExpiration() && this.oAuthService.getRefreshToken()) {
        this.oAuthService.refreshToken().then(() => {
          resolve()
        })
      } else {
        resolve()
      }
    })
  }
}
