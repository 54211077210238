import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { CalendarDate } from '../models/calendar-date'
import { environment } from '../../environments/environment'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  constructor(private http: HttpClient) {}

  getCalendarDates(chantierCode: string, materielId: number, date: string): Observable<CalendarDate[]> {
    const path = `${environment.baseUrl}/chantiers/${chantierCode}/materiels/` + materielId + `/calendrier`
    return this.http
      .get<CalendarDate[]>(path, { params: { date: date } })
      .pipe(
        map((calendarDates) => calendarDates.map((calendarDate) => CalendarService.enrichCalendarDate(calendarDate)))
      )
  }

  private static enrichCalendarDate(calendarDate: CalendarDate): CalendarDate {
    calendarDate.date = new Date(calendarDate.date)
    return calendarDate
  }
}
