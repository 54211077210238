<div *ngIf="!loading">
  <div class="page-menu">
    <button (click)="openFilterModal()" [class.invisible]="!materielCount" [ngClass]="allCategorieSelected ? 'btn-outline-dark' : 'btn-outline-primary'"
            class="btn" type="button">
      <i [ngClass]="allCategorieSelected ? 'bi-funnel' : 'bi-funnel-fill'" class="bi"></i>
      <span class="ms-2">Filtres</span>
    </button>

    <div>
      <ng-container *ngIf="selectedTab === 'à-réceptionner'">
        Matériels à réceptionner
        ({{getLibelleCountAReceptionner()}})
      </ng-container>
      <ng-container *ngIf="selectedTab === 'sur-chantier'">
        Matériels sur chantier ({{filteredMateriels.length + ' / ' + materielCount}})
      </ng-container>
      <ng-container *ngIf="selectedTab === 'historique'">
        Historique des matériels ({{filteredMateriels.length + ' / ' + materielCount}})
      </ng-container>

      <div *ngIf="selectedTab === 'à-réceptionner'" class="form-check form-switch" style="font-size: 16px;">
        <input (change)="!archived ? unSelectArchived() : selectArchived()" [(ngModel)]="archived" class="form-check-input" id="archiveToggle"
               role="switch"
               type="checkbox">
        <label class="form-check-label" for="archiveToggle" style="color: darkslategray; font-weight: 400">
          Afficher matériels archivés
        </label>
      </div>
    </div>
    <button (click)="openActionModal(actionModal)" *ngIf="selectedTab !== 'historique'" class="btn btn-primary" type="button">
      <span class="me-2">Actions</span><i class="bi bi-list"></i>
    </button>
  </div>

  <div class="row justify-content-center">
    <div *ngFor="let materiel of filteredMateriels" class="col-12 col-md-6 col-xl-4">
      <lg-materiel-card [chantierCode]="chantierCode" [materiel]="materiel"></lg-materiel-card>
    </div>

    <div class="row text-primary my-5 d-flex justify-content-center flex-column" style="min-height: 5rem">
          <span *ngIf="!loadingMoreMateriels && moreMaterielsToLoad" class="fade-in fw-bolder">
              <div class="col d-flex justify-content-center "><span (click)="onLoadMoreMateriels()" role="button">Charger plus de matériels</span></div>
              <div class="col d-flex justify-content-center"><i (click)="onLoadMoreMateriels()" class="bi bi-chevron-down load-materiel-icon fs-2" role="button"></i></div>
          </span>

      <span *ngIf="loadingMoreMateriels" class="fade-in pe-none fw-bolder">
              <div class="col d-flex justify-content-center  ">Chargement en cours...</div>
              <div class="col d-flex justify-content-center"><i class="bi bi-hourglass-split load-materiel-icon fs-2"></i></div>
          </span>

      <span *ngIf="!loadingMoreMateriels && !moreMaterielsToLoad && materielCount !== 0" class="fade-in pe-none fw-bolder">
              <div class="col d-flex justify-content-center ">Tous les matériels sont affichés</div>
              <div class="col d-flex justify-content-center"><i class="bi bi-check2-all load-materiel-icon fs-2"></i></div>
          </span>
    </div>
  </div>

  <div *ngIf="selectedTab === 'à-réceptionner' && !archived && totalNotArchivedMateriel === 0"
       class="d-flex flex-column align-items-center my-3">
    <img alt="Aucun matériel" src="/assets/images/photos/materiel-placeholder.png" style="opacity: 0.2;width: 300px;">
    <p>Vous n'avez aucun matériel à réceptionner</p>
    <button [queryParams]="{selectedTab: 'à-réceptionner'}" [routerLink]="['/chantiers', chantierCode, 'materiels', 'create']"
            class="btn btn-primary">
      Ajouter un matériel à réceptionner
    </button>
  </div>

  <div *ngIf="selectedTab === 'à-réceptionner' && archived && totalArchivedMateriel === 0"
       class="d-flex flex-column align-items-center my-3">
    <img alt="Aucun matériel" src="/assets/images/photos/materiel-placeholder.png" style="opacity: 0.2;width: 300px;">
    <p>Vous n'avez aucun matériel archivé</p>
  </div>

  <div *ngIf="selectedTab === 'sur-chantier' && materielCount === 0"
       class="d-flex flex-column align-items-center my-3">
    <img alt="Aucun matériel" src="/assets/images/photos/materiel-placeholder.png" style="opacity: 0.2;width: 300px;">
    <p>Vous n'avez aucun matériel sur le chantier</p>
    <button [queryParams]="{selectedTab: 'sur-chantier'}" [routerLink]="['/chantiers', chantierCode, 'materiels', 'create']"
            class="btn btn-primary">
      Ajouter un matériel sur le chantier
    </button>
  </div>

  <div *ngIf="selectedTab === 'historique' && materielCount === 0"
       class="d-flex flex-column align-items-center my-3">
    <img alt="Aucun matériel" src="/assets/images/photos/materiel-placeholder.png" style="opacity: 0.2;width: 300px;">
    <p>Vous n'avez aucun matériel historisé</p>
  </div>

  <div *ngIf="selectedTab !== 'à-réceptionner' && materielCount !== 0 && filteredMateriels.length === 0"
       class="d-flex flex-column align-items-center my-3">
    <img alt="Aucun matériel" src="/assets/images/photos/materiel-placeholder.png" style="opacity: 0.2;width: 300px;">
    <div>Vous n'avez aucun matériel à afficher. Vérifiez vos filtres.</div>
  </div>
</div>

<div *ngIf="loading" class="d-flex justify-content-center">
  <div class="spinner-border text-primary spinner-lg m-5" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>

<ng-template #actionModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Que voulez-vous faire ?</h4>
    <button (click)="modal.dismiss()" aria-label="Close" class="btn-close" type="button"></button>
  </div>
  <div class="modal-body">
    <div class="list-group">
      <button (click)="pointerMaterielsActifsAction();modal.close()" *ngIf="selectedTab === 'sur-chantier'" [disabled]="pointerMaterielActifLoading"
              class="list-group-item list-group-item-action" type="button">
        <ng-container *ngIf="pointerMaterielActifLoading">
          <span aria-hidden="true" class="spinner-border spinner-border-sm mx-auto spinner-md" role="status"></span>
          <span class="visually-hidden">Loading...</span>
        </ng-container>
        <ng-container *ngIf="!pointerMaterielActifLoading">
          <i class="bi bi-check2-square me-2"></i> Pointer les matériels actifs
        </ng-container>
      </button>
      <ng-container *ngIf="selectedTab === 'à-réceptionner'">
        <button (click)="modal.close()"
                [queryParams]="{ selectedTab: 'à-réceptionner'}"
                [routerLink]="['/chantiers', chantierCode, 'materiels', 'create']" class="list-group-item list-group-item-action">
          <i class="bi bi-plus-square me-2"></i>
          Ajouter un matériel à réceptionner
        </button>
      </ng-container>

      <ng-container *ngIf="selectedTab === 'sur-chantier'">
        <button (click)="modal.close()"
                [queryParams]="{ selectedTab: 'sur-chantier'}"
                [routerLink]="['/chantiers', chantierCode, 'materiels', 'create']" class="list-group-item list-group-item-action">
          <i class="bi bi-plus-square me-2"></i>
          Ajouter un matériel sur chantier
        </button>
      </ng-container>

    </div>
  </div>
  <div class="modal-footer">
    <button (click)="modal.dismiss()" class="d-block w-100 btn btn-outline-secondary" type="button">Fermer</button>
  </div>
</ng-template>
