<div class="container small-container mb-4" *ngIf="materiel" style="padding-left: 6px; padding-right: 6px">
  <div class="page-menu">
    <button class="btn btn-light float-start" (click)="onReturn()">
      <i class="bi bi-chevron-left"></i><span class="ms-2">Retour</span>
    </button>
    <div>
      <div>{{materiel.nom}}</div>
      <div style="font-size: 14px;font-weight: 400;color: gray;margin-top: -5px;">{{materiel.locatier.nom}}</div>
    </div>
    <button class="btn btn-primary float-end" (click)="modalService.open(actionModal)">
      <span class="me-2">Actions</span><i class="bi bi-list"></i>
    </button>
  </div>

  <!-- Présentation -->
  <div class="materiel-presentation mb-3" [ngClass]="!materielPhotoUrl ? 'loadingPhoto' : ''">
    <ng-container *ngIf="!materielPhotoUrl">
      <span aria-hidden="true" class="spinner-border text-primary" role="status"></span>
      <span class="visually-hidden">Loading...</span>
    </ng-container>
    <img *ngIf="materielPhotoUrl" [src]="materielPhotoUrl" class="rounded">
    <!-- Badges sur l'image -->
    <div class="over-badge-vgp" [ngClass]="materiel.getAlerteVgp()"
         *ngIf="materiel.getStatut() === 'SUR_CHANTIER' && materiel.getAlerteVgp()">
      <i class="bi bi-exclamation-triangle-fill"></i>
      <span>VGP</span>
    </div>
    <div class="over-badges">
      <div class="over-badge over-badge-numero" *ngIf="materiel.numParc || materiel.numSerie">
        <ng-container *ngIf="materiel.numParc || !materiel.numSerie; else numSerie">
          <div class="label-numero">N° de parc</div>
          <div class="value-numero">{{materiel.numParc}}</div>
        </ng-container>
        <ng-template #numSerie>
          <div class="label-numero">N° de série</div>
          <div class="value-numero">{{materiel.numSerie}}</div>
        </ng-template>
      </div>
      <div class="over-badge" *ngIf="materiel.tarifLocation">
        {{materiel.tarifLocation + ' € / ' + (materiel.uniteTemps | uniteTempsLabel)}}
      </div>
    </div>
  </div>

  <!-- Détails -->
  <div class="mb-3">
    <ngb-accordion id="accordion-detail">
      <ngb-panel title="Voir détails">
        <ng-template ngbPanelContent>
          <dl class="row mb-0">
            <dt class="col-7">Catégorie</dt>
            <dd class="col-5 text-truncate">{{materiel.categorie.nom || '-'}}</dd>

            <dt class="col-7">Numéro commande</dt>
            <dd class="col-5 text-truncate">{{materiel.numCommande || '-'}}</dd>

            <dt class="col-7">Numéro parc</dt>
            <dd class="col-5 text-truncate">{{materiel.numParc || '-'}}</dd>

            <dt class="col-7">Numéro de série</dt>
            <dd class="col-5 text-truncate">{{materiel.numSerie || '-'}}</dd>

            <dt class="col-7">Périodicité VGP</dt>
            <dd class="col-5 text-truncate">{{materiel.periodeVGP ? materiel.periodeVGP + ' mois' : '-'}}</dd>

            <dt class="col-7">Date de la dernière VGP</dt>
            <dd class="col-5 text-truncate">{{(materiel.dateVGP | date: 'dd.MM.yyyy') || '-'}}</dd>

            <dt class="col-7">Tarif transport aller</dt>
            <dd class="col-5 text-truncate">{{(materiel.tarifTransportAller || '-') + ' €'}}</dd>

            <dt class="col-7">Tarif transport retour</dt>
            <dd class="col-5 text-truncate">{{(materiel.tarifTransportRetour || '-') + ' €'}}</dd>

            <dt class="col-12 text-truncate">Commentaire</dt>
            <dd class="col-12" style="white-space: pre-wrap;">{{materiel.commentaire || '-'}}</dd>

          </dl>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>

  <!-- Pièces Jointes -->
  <div class="mb-3" *ngIf="materiel.piecesJointes.length">
    <ngb-accordion id="accordion-piece-jointes">
      <ngb-panel>
        <ng-template ngbPanelTitle>
          <div class="d-flex align-items-baseline gap-1">
            <div>Voir pièces jointes</div>
            <span class="badge bg-secondary">{{materiel.piecesJointes.length}}</span>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <table class="table">
            <tbody>
              <tr *ngFor="let pieceJointe of materiel.piecesJointes">
                <td><button (click)="downloadPieceJointe(pieceJointe.id);$event.stopPropagation()" class="list-group-item list-group-item-action" type="button">
                  <i class="bi bi-download me-2"></i>
                </button></td>
                <td>
                  {{pieceJointe.fileName}}
                </td>
                <td>
                  <button (click)="deletePieceJointe(pieceJointe.id);$event.stopPropagation()" class="list-group-item list-group-item-action" type="button">
                    <i class="bi bi-trash me-2"></i>
                  </button>

                </td>
              </tr>
            </tbody>
          </table>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>

  <!-- Composants -->
  <div class="mb-3" *ngIf="materiel.composants.length">
    <ngb-accordion id="accordion-composants">
      <ngb-panel>
        <ng-template ngbPanelTitle>
          <div class="d-flex align-items-baseline gap-1">
            <div>Voir composants</div>
            <span class="badge bg-secondary">{{materiel.composants.length}}</span>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <table class="table">
            <thead>
            <tr>
              <th colspan="4" class="text-center">
                Inventaire du {{getLastEvenementComposantDate() | date:'dd.MM.yyyy'}}
              </th>
            </tr>
            <tr>
              <th>Nom</th>
              <th class="text-center" style="width: 60px">Qté</th>
              <th class="text-center" style="width: 60px">Unité</th>
              <th class="text-center" style="width: 80px">P.U.</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let composant of materiel.composants">
              <td>{{composant.nom}}</td>
              <td class="text-end">{{composant | quantiteComposant: materiel}}</td>
              <td class="text-center">{{composant.unite === 'KG' ? 'Kg' : 'Pce'}}</td>
              <td
                class="text-end">{{composant.prixUnitaire !== null ? composant.prixUnitaire.toFixed(2) + ' €' : '-'}}</td>
            </tr>
            </tbody>
          </table>
          <p class="text-muted text-center" style="font-size: 13px"><small>Pour modifier ces quantités, créez un pointage "Composants: réception ou restitution".</small></p>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>

  <!-- Dates -->
  <div class="d-flex justify-content-center">
    <div *ngIf="materiel.getStatut() === 'SUR_CHANTIER' || materiel.getStatut() === 'HISTORISE'"
         class="materiel-periode mb-3">
      <div class="materiel-date">
        <div>Du</div>
        <div><strong>{{materiel.getDateReception() | date: 'dd.MM.yyyy'}}</strong></div>
      </div>
      <div><i class="bi bi-arrow-right"></i></div>
      <div class="materiel-date">
        <div>Au</div>
        <div [ngClass]="materiel.getLastEvenementDateCss()">
          <strong *ngIf="materiel.evenements && materiel.evenements.length > 0">
            {{materiel.getLastEvenement().date | date: 'dd.MM.yyyy'}}
          </strong>
        </div>
      </div>
    </div>
    <div *ngIf="materiel.getStatut() === 'A_RECEPTIONNER'" class="materiel-periode mb-3">
      <div class="materiel-date">
        <div>Date de réception estimée</div>
        <div><strong>{{materiel.dateReceptionEstimee | date: 'dd.MM.yyyy'}}</strong></div>
      </div>
    </div>
  </div>

  <div *ngIf="materiel.getStatut() === 'SUR_CHANTIER'" class="flex-container">
    <button (click)="pointerUntilToday(); $event.stopPropagation()" class="btn btn-outline-danger py-2 flex-grow-1"
            type="button">
        <strong>Pointer jusqu'à aujourd'hui</strong>
    </button>
    <i class="bi bi-sign-stop-fill red-icon" (click)="endLocation(); $event.stopPropagation()"></i>
  </div>

  <!-- Calendrier -->
  <div *ngIf="materiel.getStatut() === 'SUR_CHANTIER' || materiel.getStatut() === 'HISTORISE'"
       class="d-flex align-items-center flex-column mb-3">
    <ngb-datepicker #calendar (dateSelect)="selectDate($event)" (navigate)="changeMonth($event)"
                    [dayTemplate]="customDay" [markDisabled]="isFutur"
                    class="ru-shadow-3 mb-3" navigation="arrows"></ngb-datepicker>
    <button (click)="calendar.navigateTo()" class="btn btn-outline-danger"><i
      class="bi bi-calendar-day"></i>&nbsp;Retour au mois actuel
    </button>
  </div>
</div>

<ng-template #customDay let-currentMonth="currentMonth" let-date let-focused="focused" let-selected="selected"
             let-today="today">
  <div class="calendar-date">
    <div *ngIf="getShape(date) === 'TRIANGLE'" [ngClass]="getCssClass(date)">
      <i class="bi bi-play-fill"></i>
      <span>{{ date.day }}</span>
    </div>

    <div *ngIf="getShape(date) === 'SQUARE'" [ngClass]="getCssClass(date)">
      <i class="bi bi-square-fill"></i>
      <span>{{ date.day }}</span>
    </div>

    <div *ngIf="getShape(date) === 'FILLED_CIRCLE' || getShape(date) === 'EMPTY_CIRCLE'" [ngClass]="getCssClass(date)">
      {{ date.day }}
    </div>

    <div *ngIf="getShape(date) === 'NO_SHAPE'" [ngClass]="getCssClass(date)">
      {{ date.day }}
    </div>
  </div>


  <span *ngIf="getNbEvenements(date) > 1" class="evenement-number">{{getNbEvenements(date)}}</span>
</ng-template>

<ng-template #chooseEvenementModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Choix de l'événement</h4>
  </div>
  <div class="modal-body">
    <div class="list-group">
      <button *ngFor="let evenement of modalEvenements; let i = index"
              (click)="navigateToEvenement(evenement); modal.dismiss()"
              [disabled]="evenement.type.code === 'RCP' || evenement.type.code === 'SRT'"
              class="list-group-item list-group-item-action">
        <i class="me-2"></i> {{i + 1}}. {{evenement.type.nom}}
        <span class="ms-2 badge text-bg-secondary text-white fw-normal" *ngIf="evenement.type.code === 'RCP'">
          Modifiable via la modification du matériel
        </span>
        <span *ngIf="evenement.type.code === 'SRT'" class="ms-2 badge text-bg-secondary text-white fw-normal">
          Supprimable via l'onglet Historique
        </span>
      </button>
      <button (click)="navigateToNewEvenement(this.modalDate); modal.dismiss()"
              class="list-group-item list-group-item-action">
        <i class="bi bi-plus-square me-2"></i> Nouvel événement
      </button>
    </div>
  </div>
  <div class="modal-footer">
    <button class="d-block w-100 btn btn-outline-secondary" (click)="modal.dismiss()">
      Fermer
    </button>
  </div>
</ng-template>

<ng-template #actionModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Que voulez-vous faire ?</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <div class="list-group">
      <button type="button" class="list-group-item list-group-item-action" (click)="downloadPdf()"
              [disabled]="downloadingPdf">
        <ng-container *ngIf="downloadingPdf">
          <span class="spinner-border spinner-border-sm mx-auto spinner-md" role="status" aria-hidden="true"></span>
          <span class="visually-hidden">Loading...</span>
        </ng-container>
        <ng-container *ngIf="!downloadingPdf">
          <i class="bi bi-file-earmark-pdf me-2"></i> Télécharger le compte rendu
        </ng-container>
      </button>

      <button type="button" class="list-group-item list-group-item-action"
              (click)="modal.dismiss();modalService.open(documentsModal)">
        <i class="bi bi-paperclip me-2"></i> Joindre des documents
      </button>

      <button type="button" class="list-group-item list-group-item-action" (click)="modal.dismiss();clicCallGooglePicker()">
        <i class="bi bi-paperclip me-2"></i> Joindre des documents depuis Google Drive
      </button>

      <button type="button" class="list-group-item list-group-item-action"
              [routerLink]="['/chantiers', chantierCode, 'materiels', materiel.id, 'edit']"
              (click)="modal.dismiss()">
        <i class="bi bi-pencil-square me-2"></i> Modifier le matériel
      </button>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="d-block w-100 btn btn-outline-secondary" (click)="modal.dismiss()">Fermer</button>
  </div>
</ng-template>

<ng-template #documentsModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Joindre des documents</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <div class="list-group">
      <button type="button" class="list-group-item list-group-item-action">
        <i class="bi bi-paperclip me-2"></i>
        <input (change)="this.onPieceJointeChange($event)" class="form-control" type="file" id="formFile">
      </button>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="d-block w-100 btn btn-primary" (click)="this.uploadPieceJointe();$event.stopPropagation()">Joindre</button>
  </div>
</ng-template>

<ng-template #documentsAlertModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Joindre des documents</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body">
      Veuillez sélectionner un fichier à uploader
  </div>
  <div class="modal-footer">
    <button type="button" class="d-block w-100 btn btn-primary" (click)="modal.dismiss()">Fermer</button>
  </div>
</ng-template>
